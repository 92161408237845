import React, { useEffect, useRef, useState } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import {
  LocationClient,
  SearchPlaceIndexForPositionCommand,
  SearchPlaceIndexForTextCommand,
} from "@aws-sdk/client-location";
import { useNavigate } from "react-router-dom"; // For routing
import redMarker from "../src/Assets/SKImages/red-marker1.png"; // Adjust path as needed
import "./../src/Components/Shop/Shop.css"
import { FaSearchLocation } from "react-icons/fa";


const MapComponent = ({ onLocationSelect }) => {
  const mapContainerRef = useRef(null);
  const [clickLocation, setClickLocation] = useState(null);
  const [marker, setMarker] = useState(null);
  const mapRef = useRef(null);
  const [searchText, setSearchText] = useState(""); // Store search input
  const navigate = useNavigate(); // React Router's navigate function
  const markerRef = useRef(null); // Using useRef to store marker


  useEffect(() => {
    const apiKey = process.env.REACT_APP_AWS_API_KEY;
    const mapName = process.env.REACT_APP_MAP_NAME;
    const region = process.env.REACT_APP_AWS_REGION;

    if (!apiKey) {
      console.error("API key is missing. Please add it to your .env file.");
      return;
    }

    const map = new maplibregl.Map({
      container: mapContainerRef.current,
      style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
      center: [77.63513, 12.908495],
      zoom: 14,
    });

    mapRef.current = map;
    map.addControl(new maplibregl.NavigationControl(), "top-left");

    map.on("click", (e) => {
      const { lng, lat } = e.lngLat;
      setClickLocation({ lng, lat });

      addMarker(lng, lat);
      reverseGeocode(lng, lat);
    });

    return () => {
      map.remove();
    };
  }, []);

  const addMarker = (lng, lat) => {
    // If there's already a marker, remove it before adding a new one
    if (markerRef.current) {
      markerRef.current.remove();
    }
  
    // Create a new marker and add it to the map
    const newMarker = new maplibregl.Marker({
      anchor: "bottom",
      element: createMarkerElement(),
    })
      .setLngLat([lng, lat])
      .addTo(mapRef.current);
  
    // Store the new marker in the ref
    markerRef.current = newMarker;
  };

  const createMarkerElement = () => {
    const markerElement = document.createElement("div");
    markerElement.style.width = "50px";
    markerElement.style.height = "50px";
    markerElement.style.backgroundImage = `url(${redMarker})`;
    markerElement.style.backgroundSize = "contain";
    markerElement.style.backgroundRepeat = "no-repeat";
    markerElement.style.cursor = "pointer";
    return markerElement;
  };

  const reverseGeocode = async (lng, lat) => {
    try {
      const client = new LocationClient({
        region: process.env.REACT_APP_AWS_REGION,
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
      });
  
      const command = new SearchPlaceIndexForPositionCommand({
        IndexName: process.env.REACT_APP_PLACE_INDEX_NAME,
        Position: [lng, lat],
      });
  
      const response = await client.send(command);
      console.log("RESPONSE", response);
  
      if (response?.Results?.length > 0) {
        const place = response.Results[0].Place;
  
        // Extracting required address components from the response
        const streetAddress = `${place.Label}`.split(',').slice(0, -2).join(',').trim();
        const city = place.Municipality || '';
        const state = place.Region || '';
        const zipCode = place.PostalCode || '';
        const country = place.Country || '';
  
        // Creating an object to store the address components
        const addressComponents = {
          streetAddress,
          city,
          state,
          zipCode,
          country,
          lat,
          lng,
        };
  
        console.log("Address Components:", addressComponents);
  
        // Update the clickLocation state with address components
        setClickLocation((prev) => {
          const updatedLocation = { ...prev, ...addressComponents };
          
          // After updating the location, navigate to the cart with the updated location
          handleSubmitLocation(updatedLocation);
          window.scrollBy({ top: 200, left: 0, behavior: "smooth" });   
          return updatedLocation;
        });
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };
  

  const searchLocation = async () => {
    try {
      const client = new LocationClient({
        region: process.env.REACT_APP_AWS_REGION,
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
      });

      const command = new SearchPlaceIndexForTextCommand({
        IndexName: process.env.REACT_APP_PLACE_INDEX_NAME,
        Text: searchText,
        MaxResults: 1,
      });

      const response = await client.send(command);
      if (response?.Results?.length > 0) {
        const location = response.Results[0].Place;
        const {
          Geometry: { Point },
        } = location;
        const [lng, lat] = Point;

        mapRef.current.flyTo({ center: [lng, lat], zoom: 14 });
        addMarker(lng, lat);
        
        setClickLocation({ lng, lat, address: location.Label });
      } else {
        console.warn("No location found for the entered address.");
      }
    } catch (error) {
      console.error("Error searching location:", error);
    }
  };

  // Handle redirect to ShoppingStep2 with selected location data
  const handleSubmitLocation = (location) => {
    if (location) {
      // Call the onLocationSelect prop to pass the location back to the parent (ShoppingStep2)
      onLocationSelect(location ); 
      navigate("/cart", { state: { location: location  } });
    } else {
      console.error("No location selected.");
    }
  };
  

  return (
    <div style={{ position: "relative" }}>
      <div className="d-flex gap-1"
        style={{
          position: "absolute",
          width:"100%",
          top: 0,
          left: 50,
          zIndex: 1,
          padding: "2px",
          borderRadius: "5px",
          marginTop:"7px"
          
        }}
      >
        <input
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchLocation();
            }
          }}
          className="map-search-input"
          placeholder="Enter an address"
          
        />
        <button  onClick={searchLocation} className="Map-button"><FaSearchLocation style={{color:"#fff"}} /></button>
        
      </div>

      <div ref={mapContainerRef} style={{ width: "auto", height: "80vh" }} />

      {/* {clickLocation && (
        <div
          style={{
            padding: "10px",
            background: "#fff",
            position: "absolute",
            top: 60,
            left: 10,
            zIndex: 1,
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <p>Latitude: {clickLocation.lat}</p>
          <p>Longitude: {clickLocation.lng}</p>
          {clickLocation.address && <p>Address: {clickLocation.address}</p>}
        </div>
      )} */}
    </div>
  );
};

export default MapComponent;
