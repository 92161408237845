// src/Page/NotFound.jsx
import React from 'react';
import page404 from "../../src/Assets/SKImages/Error 404.png";

const PageNotFound = () => {
  // Internal CSS styles
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: 0
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    height: 'auto',
    width: '100%'
  };

  return (
    <div style={containerStyle}>
      <img src={page404} alt="404 Error" style={imageStyle} />
    </div>
  );
};

export default PageNotFound;
