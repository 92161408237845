import React from 'react'
import FAQ from '../Components/FAQ/FAQ'

const Faq = () => {
  return (
    <div>
      <FAQ/>
    </div>
  )
}

export default Faq
