import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeBanner.css";
import bgImage from "../../Assets/SKImages/testimonial-bg.png";
import clientImg1 from "../../Assets/SKImages/client-1.png";
import clientImg2 from "../../Assets/SKImages/client-2.png";
import clientImg3 from "../../Assets/SKImages/client-3.png";
import { Flex, Rate } from "antd";

const Testimonials = () => {
  const [value, setValue] = useState(3);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 2400);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 2400);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="test-container">
      <h2>WHAT OUR CUSTOMER SAYS</h2>
      <div className="row">
        <Slider {...settings} className={`${isLargeScreen ? "container" : ""}`}>
          <div className="col-lg-4 col-md-6">
            <div className="testimonial-card">
              <img src={clientImg1} alt="Client" />
              <h5 className="card-title">Arjun Bhati, Delhi</h5>
              <Flex gap="middle" vertical>
                <Rate value={3} className="ant-rate" disabled />
              </Flex>
              <p className="card-text">
                The industry leading state of the art production facility at
                Kota makes Shriram Gold cement premium and superior among all
                brands. With an aim to make every home built to last strong.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="testimonial-card">
              <img
                src={clientImg2}
                alt="../"
                className="d-flex justify-content-center align-items-center"
              />
              <h5 className="card-title">John, Mumbai</h5>
              <Flex gap="middle" vertical>
                <Rate value={5} className="ant-rate" disabled />
              </Flex>
              <p className="card-text">
                Cement is a vital construction material known for its strength, durability, and versatility in building infrastructure like houses, bridges, and roads. </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="testimonial-card">
              <img src={clientImg3} alt="Client" />
              <h5 className="card-title">Scema Construction, Mumbai</h5>
              <Flex gap="middle" vertical>
                <Rate value={2} className="ant-rate" disabled />
              </Flex>
              <p className="card-text">
                Cement is a durable and versatile construction material essential for building strong and long-lasting structures. Its eco-friendly advancements and adaptability to modern innovations make it a reliable choice for diverse construction needs.
              </p>
            </div>
          </div>

        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
