import React from "react";
import Slider from "react-slick";
import "./HomeBanner.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const HomeBanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  const navigate = useNavigate();

  const handleShop = () => {
    navigate("/shop");
  };
  const handleAbout = () => {
    navigate("/about");
  };
  const handleContact = () => {
    navigate("/contact-us");
  };

  return (
    <div className="container-fluid" style={{ overflow: "hidden" }}>
      <Slider {...settings} className="home-banner-slide">
        <div className="home-banner-1 ">
          <div className="col-lg-7 mobile-div-hide"></div>
          <div className="col-lg-5 banner-content">
            <h4>Welcome to Builderneed</h4>
            <h2
              className="home-banner-h2-your-trusted"
              style={{ textTransform: "uppercase", letterSpacing: "1px" }}
            >
              YOUR TRUSTED PARTNER IN
            </h2>
            <h1>
              BUILDING <span>MATERIALS</span>
            </h1>
            <p>
              Explore a wide range of high quality cements tailored for your
              constructing needs
            </p>
            <button className="faq-get-in-touch-button" onClick={handleShop}>
              Shop Now
            </button>
          </div>
        </div>
        {/* You can add more slides here */}
        <div className="home-banner-2">
          <div className="col-lg-7  mobile-div-hide"></div>
          <div className="col-lg-5 banner-content">
            <h4
              className="home-banner-h4-purchase-cement"
              style={{ textTransform: "uppercase", letterSpacing: "1px" }}
            >
              {" "}
              Purchase Cements & Steels at
            </h4>
            <h2
              className="home-banner-h2-best-rates"
              style={{ fontWeight: "700", textTransform: "uppercase" }}
            >
              Best Rates in Bangalore
            </h2>
            <div className="banner-content-div">
              <p>Unbeatable Prices, Unmatched Quality</p>
            </div>
            <p style={{ fontSize: "22px" }}>
              Explore a wide range of high quality cements tailored for your
              constructing needs
            </p>
            <button className="faq-get-in-touch-button" onClick={handleAbout}>
              About Us
            </button>
          </div>
        </div>
        <div className="home-banner-3">
          <div className="col-lg-6  mobile-div-hide "></div>
          <div className="col-lg-5 banner-content">
            <h4
              style={{ letterSpacing: "1px", color: "#fff", fontWeight: "700" }}
              className="mobile-color-change"
            >
              SHOP TODAY & RECEIVE IN TIME
            </h4>
            <h2
              style={{ fontWeight: "700", fontSize: "48px", color: "#fff" }}
              className="mobile-color-change"
            >
              CONTACT US TODAY
            </h2>

            <p
              style={{ fontSize: "22px", color: "#fff" }}
              className="mobile-color-change"
            >
              Our special offers and discounts on all cement and steel purchases
            </p>
            <button className="faq-get-in-touch-button" onClick={handleContact}>
              Contact Us
            </button>
          </div>
        </div>
      </Slider>
    </div>
  );
};

// Custom PrevArrow component
const PrevArrow = ({ onClick }) => (
  <div className="custom-arrow custom-prev" onClick={onClick}>
    <LeftOutlined
      className="arrow-icon arrow-icon-left"
      style={{ marginLeft: "30px" }}
    />
  </div>
);

// Custom NextArrow component
const NextArrow = ({ onClick }) => (
  <div className="custom-arrow custom-next" onClick={onClick}>
    <RightOutlined
      className="arrow-icon arrow-icon-right"
      style={{ marginRight: "30px" }}
    />
  </div>
);

export default HomeBanner;
