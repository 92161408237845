import React from 'react'
import ProductImage from './ProductImage'
import ProductReview from './ProjectReview'
import RecentlyViewedCarasoul from "../../Components/Home/RecentlyViewedCarasoul";
import SteelCarousel from '../Home/SteelCarousel';


const ProductDetails = () => {
  return (
    <div>
      
     <ProductImage />
     <ProductReview />
     <RecentlyViewedCarasoul />
     <SteelCarousel/>
    </div>
  )
}

export default ProductDetails