import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined, HeartOutlined } from "@ant-design/icons";
import "../Components/Profile/WishList/wishlist.css";
import Instance from "../AxiosConfig";
import { Rate, message, Spin } from "antd";
import { addWishlists, removeWishlist } from "../states/reducers/wishlistSlice";
import SteelCarousel from "../Components/Home/SteelCarousel";
import RecentlyViewedCarasoul from "../Components/Home/RecentlyViewedCarasoul";
import { useNavigate, useLocation } from "react-router-dom";
import INRrupee from "../Components/Home/INRrupee";
import { addItems, addItem } from "../states/reducers/AddToCartSlice";

export const WishlistLogout = () => {
  const dispatch = useDispatch();
  const wishlistItems = useSelector((state) => state.wishlist.wishlistsData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleShop = () => {
    navigate("/shop");
  };

  const handleCardClick = (productId) => {
    console.log("Navigating to product:", productId);
    navigate(`/product-details/${productId}`);
  };

  const fetchWishlistData = async () => {
    setLoading(true);
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const url = `/api/v1/wishlist/?sessionId=${sessionId}`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("Wishlist data fetched successfully:", response.data);
        const modifiedData = response.data.map((item) => ({
          ...item,
        }));
        dispatch(addWishlists(modifiedData));
      }
    } catch (error) {
      // message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWishlistData();
  }, []);

  const addToCart = async (productId, cartItemDtos = []) => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem('sessionId', sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem('sessionId');
      }
      const url = `api/v1/cart/add/${productId}${sessionId ? `?sessionId=${sessionId}` : ''}`;
      const response = await Instance.post(
        url,
        cartItemDtos,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
        }
      );
      if (response.status === 200) {
        message.success(response.data.message);
        dispatch(addItem(response.data));
        fetchCartCount();
        console.log(response);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || 'An error occurred while adding to cart');
      console.log("Error:", error?.response);
    }
  };
  const fetchCartCount = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.get(
        `/api/v1/cart/?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(addItems(response.data.cartItems))
      }

    } catch (error) {
      console.error("Error fetching cart count:", error);
    }
  };
  const generateSessionId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const handleAddToCart = (e, product) => {
    e.stopPropagation();
    if (product.categoryTitle.toLowerCase() === "steel".toLowerCase()) {
      handleCardClick(product.productId);
    } else {
      addToCart(product.productId);
    }
  };

  const handleDelete = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const url = `/api/v1/wishlist/product/${productId}?sessionId=${sessionId}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        message.success(response?.data?.message);
        dispatch(removeWishlist(productId));
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };
  return (
    <>
      <div className="container mt-4 mb-4">
        <h4 className="d-flex justify-content-center mb-4 mt-4">Your Wishlist</h4>
        <div className="row  d-flex gap-2">
          {loading ? (
            <Spin size="large" />
          ) : wishlistItems.length === 0 ? (
            <div className="empty-wishlist">
              <HeartOutlined className="wishlist-like-symbol" />
              <h3>Your Wishlist is empty</h3>
              <p>
                You don’t have any products in the wishlist yet. You will find a
                lot of interesting products on our Shop page.
              </p>
              <button className="faq-get-in-touch-button" onClick={handleShop}>Continue Shopping</button>
            </div>
          ) : (
            wishlistItems.map((item) => (
              <>
                <div
                  key={item.productId}
                  className=" wishlist-item  mb-4"
                  style={{ width: "550px" }}>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2">
                      <img
                        src={`data:image/jpeg;base64,${item.imageData}`}
                        alt={item.title}
                        style={{ width: "150px", height: "150px", cursor: "pointer" }}
                        onClick={() => handleCardClick(item.productId)}
                      />
                      <div>
                        <h2>{item.title}</h2>
                        <p>Brand: {item.brand}</p>
                        <p className="mt-2" style={{ fontSize: "14px" }}> {item.description}</p>
                        <p className="mt-2">
                          <b><INRrupee /> {item.discountedPrice}</b> <span><INRrupee /> {item.price}</span>
                        </p>
                        <Rate
                          disabled
                          defaultValue={item.numRatings}
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            paddingTop: "20px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column  align-content-end">
                      <CloseOutlined
                        style={{ justifyContent: "flex-end" }}
                        onClick={() => handleDelete(item.productId)}
                      />
                      <div className="d-flex" style={{ marginTop: "120px" }}>

                      </div>
                    </div>
                    
                  </div>
                  <div  className="d-flex   justify-content-end">
                      <button

                        className="add-to-cart-button add-to-cart-buttons  d-flex justify-content-end"
                        onClick={(e) => handleAddToCart(e, item)}
                      >
                        Add To Cart
                      </button>
                    </div>

                </div>


              </>



            ))
          )}
        </div>
      </div>
      <SteelCarousel />
      <RecentlyViewedCarasoul />
    </>
  )
}
