import React, { useState, useEffect, useRef } from "react";
import "./details.css";
import { useNavigate } from "react-router-dom";
import Instance from "../../../AxiosConfig";
import { message, Spin } from "antd";
import Ellipse from "../../../Assets/SKImages/Ellipse 523.png";
import { updateUserDetails } from "../../../states/reducers/AuthSlice";
import { useDispatch } from "react-redux";
export const MyDetails = () => {
  const navigate = useNavigate();
  const handleAddAddress = () => {
    navigate("/profile/details/add-address");
  };
  const dispatch = useDispatch();
  const handleEditAddress = (address) => {
    navigate("/profile/details/edit-address", { state: { address } });
  };
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([""]);
  const [editableFields, setEditableFields] = useState({
    fullName: false,
    email: false,
    mobile: false,
  });

  const [activeField, setActiveField] = useState(null);
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const [errors, setErrors] = useState({});
  const fullNameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const mobileInputRef = useRef(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1600);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Add event listener to handle screen resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 2400);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await Instance.get("/api/v1/user/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        setUserData(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchAddressData = async () => {
    try {
      const url = "/api/v1/address/";
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("Address data fetched successfully:", response.data);
        setAddresses(response.data);
      }
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };

  useEffect(() => {
    fetchAddressData();
  }, []);

  const deleteAddress = async (addressId) => {
    try {
      const url = `/api/v1/address/${addressId}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("response", response);
        message.success(response.data.message);
        // setAddresses(addresses.filter(address => address.id !== id));
        fetchAddressData();
      }
    } catch (error) {
      console.error("Error removing address:", error);
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };

  const validateFields = () => {
    const newErrors = {};

    // Name Validation
    if (!userData.fullName) {
      newErrors.fullName = "Name is required";
    } else if (userData.fullName.length < 3) {
      newErrors.fullName = "Name must contain at least 3 characters";
    } else if (userData.fullName.length > 15) {
      newErrors.fullName = "Name must be less than 15 characters";
    } else if (!/^[a-zA-Z\s]+$/.test(userData.fullName)) {
      newErrors.fullName = "Name can only contain letters";
    }

    // Mobile Validation
    if (!userData.mobile) {
      newErrors.mobile = "Mobile Number is required";
    } else if (!/^[4-9]\d{9}$/.test(userData.mobile)) {
      newErrors.mobile =
        "Mobile Number must start with 4-9 and be exactly 10 digits long";
    }

    // Email Validation
    if (!userData.email) {
      newErrors.email = "Email is required";
    } else if (!/^\S+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userData.email)) {
      newErrors.email = "Email is invalid";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFieldChange = (field) => {
    setEditableFields({
      fullName: field === "fullName",
      email: field === "email",
      mobile: field === "mobile",
    });

    if (field === "fullName" && fullNameInputRef.current) {
      fullNameInputRef.current.focus();
      fullNameInputRef.current.setSelectionRange(
        fullNameInputRef.current.value.length,
        fullNameInputRef.current.value.length
      );
    } else if (field === "email" && emailInputRef.current) {
      emailInputRef.current.focus();
      emailInputRef.current.setSelectionRange(
        emailInputRef.current.value.length,
        emailInputRef.current.value.length
      );
    } else if (field === "mobile" && mobileInputRef.current) {
      mobileInputRef.current.focus();
      mobileInputRef.current.setSelectionRange(
        mobileInputRef.current.value.length,
        mobileInputRef.current.value.length
      );
    }
  };

  const handleNameChange = (e) => {
    let { value } = e.target;

    // Remove any characters that are not letters (a-z or A-Z)
    value = value.replace(/[^a-zA-Z\s]/g, "");

    // Update the fullName value in userData
    setUserData({ ...userData, fullName: value });

    // Validate as user types
    if (value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "Name is required",
      }));
    } else if (value.length < 3) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "Name must contain at least 3 characters",
      }));
    } else if (value.length > 15) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fullName: "Name must be less than 15 characters",
      }));
    } else {
      setErrors((prevErrors) => {
        const { fullName, ...rest } = prevErrors;
        return rest; // Remove the fullName error if it passes validation
      });
    }
  };
  const handleMobileChange = (e) => {
    const { value } = e.target;

    // Restrict input to only 10 digits
    if (value.length > 10) {
      return; // Do not update the state if the length exceeds 10
    }

    // Update the mobile value in userData
    setUserData({ ...userData, mobile: value });

    // Validate as user types
    if (value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Mobile Number is required",
      }));
    } else if (!/^[4-9]/.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Mobile Number must start with 4-9",
      }));
    } else if (value.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: "Mobile Number must be exactly 10 digits long",
      }));
    } else {
      setErrors((prevErrors) => {
        const { mobile, ...rest } = prevErrors;
        return rest; // Remove the mobile error if it passes validation
      });
    }
  };

  const updateUser = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }

    try {
      setLoading(true);
      const response = await Instance.put("/api/v1/user/update", userData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        message.success("User data updated successfully");
        console.log("Data to update:", userData); // Ensure this is an object
        dispatch(updateUserDetails(userData));

        setEditableFields({
          fullName: false,
          email: false,
          mobile: false,
        });
      }
    } catch (error) {
      console.error("Error updating user data:", error.response.data);
      message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={
          isLargeScreen
            ? "MyDetails-container"
            : "container MyDetails-container"
        }
      >
        <div className="row">
          <h3 style={{ color: "#1b3a57", textAlign: "start" }} className="mb-2">
            My Detail
          </h3>
          <h4 style={{ color: "#000", textAlign: "start" }} className="mb-2">
            Contact Details
          </h4>
          <form>
            <div className="form-group mb-3">
              <div className="d-flex align-items-center">
                <div className="form-floating flex-grow-1">
                  <input
                    type="text"
                    className="form-control my-details-form"
                    value={userData.fullName}
                    readOnly={!editableFields.fullName}
                    onChange={handleNameChange}
                    ref={fullNameInputRef}
                  />
                  <label htmlFor="floatingName">Your Name</label>
                  <div className="error-container-details">
                    {errors.fullName && (
                      <div className="error-message-details-page">
                        {errors.fullName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="button-container-detalis">
                  <button
                    type="button"
                    className={`change-address-button ${
                      editableFields.fullName ? "active" : ""
                    }`}
                    onClick={() => handleFieldChange("fullName")}
                  >
                    Change
                  </button>
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <div className="d-flex align-items-center">
                <div className="form-floating flex-grow-1">
                  <input
                    type="email"
                    className="form-control my-details-form"
                    value={userData.email}
                    readOnly={!editableFields.email}
                    onChange={(e) =>
                      setUserData({ ...userData, email: e.target.value })
                    }
                    ref={emailInputRef}
                  />
                  <label htmlFor="floatingName">Email Address</label>
                  <div className="error-container-details">
                    {errors.email && (
                      <div className="error-message-details-page">
                        {errors.email}
                      </div>
                    )}
                  </div>
                </div>
                <div className="button-container-detalis">
                  {/* Uncomment if needed */}
                  {/* <button
        type="button"
        className={`change-address-button ${editableFields.email ? 'active' : ''}`}
        onClick={() => handleFieldChange('email')}
      >
        Change
      </button> */}
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <div className="d-flex align-items-center">
                <div className="form-floating flex-grow-1">
                  <input
                    type="text"
                    className="form-control my-details-form"
                    value={userData.mobile}
                    readOnly={!editableFields.mobile}
                    onChange={handleMobileChange}
                    ref={mobileInputRef}
                  />
                  <label htmlFor="floatingName">Phone Number</label>
                  <div className="error-container-details">
                    {errors.mobile && (
                      <div className="error-message-details-page">
                        {errors.mobile}
                      </div>
                    )}
                  </div>
                </div>
                <div className="button-container-detalis">
                  <button
                    type="button"
                    className={`change-address-button ${
                      editableFields.mobile ? "active" : ""
                    }`}
                    onClick={() => handleFieldChange("mobile")}
                  >
                    Change
                  </button>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-start mt-5">
              <button
                className="faq-get-in-touch-button"
                onClick={updateUser}
                disabled={loading}
              >
                {loading ? <Spin /> : "Update"}
              </button>
            </div>
            <div className="mt-4">
              <div className="d-flex justify-content-between">
                <h3>Address</h3>
                <button
                  className="faq-get-in-touch-button"
                  onClick={handleAddAddress}
                >
                  Add New
                </button>
              </div>
              <div className=" media-add-address profile-delivery-address">
                {addresses
                  .slice(
                    0,
                    showAllAddresses ? addresses.length : isMobile ? 1 : 2
                  )
                  .map((address, index) => (
                    <div key={index} className="address-card">
                      <div className="d-flex gap-4 myDetails-address-mobile">
                        <div className="">
                          <img src={Ellipse} alt="" />
                        </div>
                        <div className="delivery-address-details">
                          <h4>{`${address.firstName} ${address.lastName}`}</h4>
                          <p>{address.streetAddress}</p>
                          <p>
                            {address.state}&nbsp;
                            {`${address.city} - ${address.zipCode}`}
                          </p>
                          <p>{`Phone: ${address.mobile}`}</p>

                          <div className="d-flex gap-2">
                            <button
                              className="remove-button"
                              onClick={(e) => {
                                e.preventDefault();
                                deleteAddress(address.addressId);
                              }}
                            >
                              Remove
                            </button>
                            <button
                              className="edit-button"
                              onClick={() => handleEditAddress(address)}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {addresses.length > (isMobile ? 1 : 2) && (
                <div className="justify-content-start d-flex mt-3">
                  <button
                    type="button"
                    className="faq-get-in-touch-button justify-content-start"
                    onClick={() => setShowAllAddresses(!showAllAddresses)}
                  >
                    {showAllAddresses ? "View Less" : "View More"}
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
