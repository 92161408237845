import React from "react";
import { Modal } from "antd";
import { BiError } from "react-icons/bi";
const TokenExpiredPopup = ({ visible, onClose  })=> {
    
    return (
    <Modal
    title={null}
    open={visible}
    closable={false} 
    maskClosable={false} 
      footer={[
       
        <a className="faq-get-in-touch-button" style={{textDecoration:"none",color:"#fff"}} href="/signin">
           Login
        </a>,
    ]}
    >
      <div className="d-flex gap-3">
        <div style={{borderRight:"#d0d389 1px solid",padding:"20px",alignContent:"center"}}>
            <BiError style={{color:"#ce1b28",fontSize:"100px"}}/>
        </div>
        <div>
        <h4>SESSION EXPIRED!!!</h4>
        <p>The Session has been Expired due to one of the following reasons:</p>
        <ul>
          <li>
            You might have used Back/Forward/Refresh button of your Browser.
          </li>
          <li>You might have double-clicked a button.</li>
          <li>You might have kept the browser window idle for a long time.</li>
          <li>
            You might have opened another session or logged in from another
            window.
          </li>
          <li>
            You are accessing the application URL from a saved or static page.
          </li>
        </ul>
        </div>
      </div>
    </Modal>
  );
};

export default TokenExpiredPopup;
