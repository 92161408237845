import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined, HeartOutlined } from "@ant-design/icons";
import "./wishlist.css";
import Instance from "../../../AxiosConfig";
import { Rate, message, Spin } from "antd";
import { addWishlists, removeWishlist } from "../../../states/reducers/wishlistSlice";
import { useNavigate } from "react-router-dom";
import { addItem, addItems } from "../../../states/reducers/AddToCartSlice";
export const Wishlist = () => {
  const dispatch = useDispatch();
  const wishlistItems = useSelector((state) => state.wishlist.wishlistsData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleShop = () => {
    navigate("/shop");
  };

  const handleCardClick = (productId) => {
    console.log("Navigating to product:", productId);
    navigate(`/product-details/${productId}`);
  };

  const fetchWishlistData = async () => {
    setLoading(true);
    try {
      const url = "/api/v1/wishlist/";
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("Wishlist data fetched successfully:", response.data);
        const modifiedData = response.data.map((item) => ({
          ...item,
        }));
        dispatch(addWishlists(modifiedData));
      }
    } catch (error) {
      // message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWishlistData();
  }, []);

  const generateSessionId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const addToCart = async (productId, cartItemDtos = []) => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem('sessionId', sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem('sessionId');
      }
      const url = `api/v1/cart/add/${productId}${sessionId ? `?sessionId=${sessionId}` : ''}`;
      const response = await Instance.post(
        url,
        cartItemDtos,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
        }
      );
      if (response.status === 200) {
        message.success(response.data.message);
        dispatch(addItem(response.data))
        fetchCartCount()
        console.log(response);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || 'An error occurred while adding to cart');
      console.log("Error:", error?.response);
    }
  };
  const fetchCartCount = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.get(
        `/api/v1/cart/?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(addItems(response.data.cartItems))
      }

    } catch (error) {
      console.error("Error fetching cart count:", error);
    }
  };
  const handleAddToCart = (e, product) => {
    e.stopPropagation();
    if (product.categoryTitle.toLowerCase() === "steel".toLowerCase()) {
      handleCardClick(product.productId);
    } else {
      addToCart(product.productId);
    }
  };

  const handleDelete = async (productId) => {
    try {
      const url = `/api/v1/wishlist/product/${productId}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        message.success(response?.data?.message);
        dispatch(removeWishlist(productId));
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };

  return (
    <div className="container">
      <div className="row wishlist-row mt-4">
        {loading ? (
          <Spin size="large" />
        ) : wishlistItems.length === 0 ? (
          <div className="empty-wishlist">
            <HeartOutlined className="wishlist-like-symbol" />
            <h3>Your Wishlist is empty</h3>
            <p>
              You don’t have any products in the wishlist yet. You will find a
              lot of interesting products on our Shop page.
            </p>
            <button className="faq-get-in-touch-button " onClick={handleShop} >Continue Shopping</button>

          </div>
        ) : (
          wishlistItems.map((item) => (
            <div
              key={item.productId}
              className="wishlist-item d-flex justify-content-between mb-4"
            >
              <div className="d-flex gap-2 media-wishlist">
                <img
                  src={`data:image/jpeg;base64,${item.imageData}`}
                  alt={item.title}
                  style={{ width: "150px", height: "150px", cursor: "pointer" }}
                  onClick={() => handleCardClick(item.productId)}
                />
                <div>
                  <h2>{item.title}</h2>
                  <p>Brand: {item.brand}</p>
                  <p className="mt-2" style={{ fontSize: "14px" }}>
                    {item.description}
                  </p>
                  <p className="mt-2 d-flex">
                    <b>₹ {item.discountedPrice}</b>{" "}
                    <span>₹ {item.price}</span>
                    <p className="wishlist-product-detail-discount">
                      {item?.discountPercentage}% off
                    </p>
                  </p>
                  <div className="d-flex mt-3 addtocart-button-mobile align-items-center gap-4">
                    <Rate
                      disabled
                      defaultValue={item.numRatings}
                      style={{
                        justifyContent: "start",                       
                      }}
                    />
                    <div className="d-flex justify-content-end d-block d-sm-none">
                      <button
                        className="add-to-cart-button add-to-cart-buttons"
                        onClick={(e) => handleAddToCart(e, item)}
                      >
                        Add To Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column media-closeoutlined-wishlist">
                <CloseOutlined
                  style={{ justifyContent: "flex-end" }}
                  onClick={() => handleDelete(item.productId)}
                />
                <div className="d-flex justify-content-end mt-5 d-none d-sm-block">
                  <button
                    className="add-to-cart-button add-to-cart-buttons"
                    onClick={(e) => handleAddToCart(e, item)}
                  >
                    Add To Cart
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};