import React from 'react'
import AboutBanner from './AboutBanner';
import { WhyUs } from './WhyUs';
import { OurMission } from './OurMission';
import { OurValues } from './OurValues';
import Testimonials from '../Home/Testimonials';
import { HowItWorks } from './HowItWorks';
import { AboutGetInTouch } from './AboutGetInTouch';

import Awards from "../Home/Awards"

import FaqGetInTouch from '../FAQ/FaqGetInTouch';


 const About = () => {
  return (
    <div>
      <AboutBanner/>
      <WhyUs/>
      {/* <OurMission/> */}
      <OurValues/>
      <HowItWorks/>
      <Testimonials/>

      {/* <Awards/> */}

     
      <FaqGetInTouch />

    </div>
  )
}
export default About;
