import { LocationClient, SearchPlaceIndexForPositionCommand, SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location";

// AWS configuration
const REGION = process.env.REACT_APP_AWS_REGION || 'ap-south-1'; 
const ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

if (!REGION || !ACCESS_KEY_ID || !SECRET_ACCESS_KEY) {
    throw new Error("AWS configuration is missing required environment variables.");
}

const locationClient = new LocationClient({
    region: REGION,
    credentials: {
        accessKeyId: ACCESS_KEY_ID,
        secretAccessKey: SECRET_ACCESS_KEY,
    },
});

// Function to get location based on coordinates
export const getCurrentLocation = async (latitude, longitude) => {
    try {
        const command = new SearchPlaceIndexForPositionCommand({
            IndexName: 'SK-Place-Index',
            Position: [longitude, latitude], // Longitude comes first
        });

        const response = await locationClient.send(command);
        return response;
    } catch (error) {
        console.error("Error fetching location data from coordinates:", error);
        throw error;
    }
};

// Function to get location based on an address
export const getLocationFromAddress = async (fullAddress) => {
    try {
        const command = new SearchPlaceIndexForTextCommand({
            IndexName: 'SK-Place-Index',
            Text: fullAddress, // Address as input
        });

        const response = await locationClient.send(command);
        return response;
    } catch (error) {
        console.error("Error fetching location data based on address:", error);
        throw error;
    }
};
