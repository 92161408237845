import React, { useEffect, useRef, useState } from "react";
import "./HomeBanner.css"
import image1 from "../../Assets/Clients/img1.png"
import image2 from "../../Assets/Clients/img2.png"
import image3 from "../../Assets/Clients/img3.png"
import image4 from "../../Assets/Clients/img4.png"
import image5 from "../../Assets/Clients/img5.png"

const Clients = () => {
  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const images = carousel.querySelectorAll("img");
    const firstImage = images[0];
    const numberOfImages = images.length;

    carousel.append(firstImage.cloneNode(true));

    let imageWidth = firstImage.offsetWidth;
    let currentPosition = 0;

    const moveCarousel = () => {
      currentPosition += imageWidth;
      carousel.style.transform = `translateX(-${currentPosition}px)`;

      if (currentPosition === numberOfImages * imageWidth) {
        currentPosition = 0;
        carousel.style.transform = `translateX(0px)`;
      }
    };

    const startCarousel = setInterval(moveCarousel, 3000); 
    return () => clearInterval(startCarousel);
  }, []);

  return (
    <div className="carousel-container container">
      <h2>OUR TRUSTED PARTNERS</h2>
      <div className="client-carousel mt-4" ref={carouselRef}>
        <img src={image1} alt="..." />
        <img src={image2} alt="..." />
        <img src={image3} alt="..." />
        <img src={image4} alt="..." />
        <img src={image5} alt="..." />
        <img src={image1} alt="..." />
        <img src={image2} alt="..." />
        <img src={image3} alt="..." />
        <img src={image4} alt="..." />
        <img src={image5} alt="..." />
      </div>
    </div>
  );
};

export default Clients;
