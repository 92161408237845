import React from "react";
import "./Shop.css";
import { useNavigate } from "react-router-dom";

const ShopBanner = () => {
const navigate = useNavigate();

  const handleContact = () => {
    navigate("/contact-us");
  };
    return (
        <div className="container-fluid" style={{overflow:"hidden"}}>
            <div className="row shop-banner-row" >
            <div className="col-md-6  col-12 banner-content-shop ms-md-5 px-4">
            <h4 style={{letterSpacing:"1px",color:"#fff",fontWeight:"700"}}>SHOP TODAY & RECEIVE IN TIME</h4>
            <h2 style={{fontWeight:"700",fontStyle:"italic",fontSize:"48px",color:"#fff"}}>Discover Top Brands in Cements & Steels</h2>
           
            <p style={{fontSize:"22px",color:"#fff"}}>
            Explore a Wide Range of High-Quality Products for Your Construction Needs
            </p>
            <button className="explore-button-shop" onClick={handleContact}  >Contact Us</button>
          </div>
            </div>
            
        </div>
    );
};

export default ShopBanner;
