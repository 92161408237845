import { createSlice } from "@reduxjs/toolkit";

export const AddToCartSlice = createSlice({
  name: "cart",
  initialState: {
    AddToCartData: {
      cartId: null,
      cartItems: [], 
      totalPrice: 0,
      totalDiscountedPrice: 0,
      discounte: 0,
      totalItem: 0,
      discountPercentage: 0,
      couponDiscountedPrice: 0,
      totalAfterDiscount: 0,
      couponCode: null,
      couponType: null,
      couponApplied: false,
    },
  },
  reducers: {
    addItem: (state, action) => {
      if (Array.isArray(state.AddToCartData.cartItems)) {
        state.AddToCartData.cartItems.push(action.payload);
      } else {
        console.error("cartItems is not an array", state.AddToCartData.cartItems);
      }

      state.AddToCartData.totalItem = state.AddToCartData.cartItems.length;
      state.AddToCartData.totalDiscountedPrice = state.AddToCartData.cartItems.reduce(
        (acc, item) => acc + (item.discountedPrice || 0), 0
      );
    },
    addItems: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.AddToCartData.cartItems = action.payload;
      } else {
        console.error("addItems payload is not an array", action.payload);
      }

      state.AddToCartData.totalItem = state.AddToCartData.cartItems.length;
      state.AddToCartData.totalDiscountedPrice = state.AddToCartData.cartItems.reduce(
        (acc, item) => acc + (item.discountedPrice || 0), 0
      );
    },
    removeItems: (state, action) => {
      if (Array.isArray(state.AddToCartData.cartItems)) {
        state.AddToCartData.cartItems = state.AddToCartData.cartItems.filter(
          (item) => item.productId !== action.payload
        );
      } else {
        console.error("cartItems is not an array", state.AddToCartData.cartItems);
      }

      state.AddToCartData.totalItem = state.AddToCartData.cartItems.length;
      state.AddToCartData.totalDiscountedPrice = state.AddToCartData.cartItems.reduce(
        (acc, item) => acc + (item.discountedPrice || 0), 0
      );
    },
    clearCart: (state) => {
      state.AddToCartData = {
        cartId: null,
        cartItems: [],
        totalPrice: 0,
        totalDiscountedPrice: 0,
        discounte: 0,
        totalItem: 0,
        discountPercentage: 0,
        couponDiscountedPrice: 0,
        totalAfterDiscount: 0,
        couponCode: null,
        couponType: null,
        couponApplied: false,
      };
    }
  },
});

export default AddToCartSlice.reducer;
export const { addItem, addItems, removeItems,clearCart } = AddToCartSlice.actions;
