import React from 'react'
import ShopBanner from './ShopBanner'
import ShopProduct from './ShopProduct'
import FaqGetInTouch from '../FAQ/FaqGetInTouch'


const Shop = () => {
  return (
    <div>
      <ShopBanner />
      <ShopProduct />
      <FaqGetInTouch />
    </div>
  )
}

export default Shop
