import React from 'react'
import OrdersPage from "./Orderspage"

const MyOrders = () => {
    return (
      <div>
        <OrdersPage />
        
      </div>
    )
  }
  
  export default MyOrders;