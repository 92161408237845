import React, { useEffect, useState } from "react";
import appImg from "../../Assets/SKImages/mobile-app.png";
import AppLogo from "../../Assets/icons/app-2.png";
import GoogleLogo from "../../Assets/icons/google-2.png"
import Instance from "../../AxiosConfig";
import { message, Spin } from "antd";

const GetApp = () => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1600);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 1600);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); 
            handleClick(event); 
        }
    };

    const handleClick = async (e) => {
        e.preventDefault();
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address');
        } else {
            setError('');
            console.log('Valid email submitted:', email);

            const data = {
                recipientEmail: email
            }

            try {
                setLoading(true);
                const response = await Instance.post("/api/v1/app/send-link", data)
                if (response.status == 200) {
                    message.success("App link sent! Please check your email.");
                    setEmail('');
                }

            } catch {
                message.error("Failed to send link. Please try again.");
                console.error("Error sending link:", error);
            }
            finally {
                setLoading(false)
            }

        }
    };

    return (
        <div className='container'>
            <div className="row align-items-center">
                <div className="col">
                    <img src={appImg} alt="app" className="app-img mb-4" />
                </div>
                <div className="col text-align-start" style={{ marginTop: "-40px" }}>
                    <h1 className=" d-flex">Get Builderneed App</h1>
                    <p style={{ textAlign: "justify" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Aenean eu tincidunt justo. Vestibulum consequat quis nunc eu aliquet.
                        Ut augue enim, auctor sit amet blandit dapibus, volutpat ut ligula. </p>
                    <div className="row gap-3 px-3">
                        <input type="email" value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Enter Email Address"
                            className="col-7 mobile-get-now"
                            style={{ border: "1px solid #1b3a57", borderRadius: "10px", padding: "4px 10px", height: "50px" }}
                            onKeyDown={handleKeyDown} />
                        <button className="col-3 faq-get-in-touch-button" onClick={handleClick}>
                            {loading ? (
                                <Spin size="small" />
                            ) : (
                                "Get Now"
                            )}
                        </button>
                    </div>
                    {error && <p style={{ color: 'red', textAlign: 'left', marginLeft: "7px" }} className="mb-0">{error}</p>}
                    <div className="d-flex gap-4 mt-4">
                        <img src={GoogleLogo} alt="google" className="footer-google" />
                        <img src={AppLogo} alt="app" className="footer-google" />
                    </div>
                </div>
            </div>
        </div>
    )

};

export default GetApp;
