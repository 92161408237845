import React, { useState } from 'react';
// import './Settings.css';
import { CiLock } from "react-icons/ci";
import { Input, message, Spin } from 'antd';
import Instance from '../../../AxiosConfig';

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleOldPasswordChange = (e) => setOldPassword(e.target.value);
    const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
    const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

    const [errors, setErrors] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const validatePasswords = () => {
        const newErrors = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        };

        if (!newPassword) {
            newErrors.newPassword = "Password is required";
        } else if (newPassword.length < 8) {
            newErrors.newPassword = "Password must be at least 8 characters";
        } else if (!/[A-Z]/.test(newPassword)) {
            newErrors.newPassword = "Password must contain at least one uppercase letter";
        } else if (!/[a-z]/.test(newPassword)) {
            newErrors.newPassword = "Password must contain at least one lowercase letter";
        } else if (!/[0-9]/.test(newPassword)) {
            newErrors.newPassword = "Password must contain at least one number";
        } else if (!/[!@#$%^&*]/.test(newPassword)) {
            newErrors.newPassword = "Password must contain at least one special character";
        }

        if (newPassword !== confirmPassword) {
            newErrors.newPassword = 'New password and confirm password do not match.';
            newErrors.confirmPassword = 'New password and confirm password do not match.';
        }

        setErrors(newErrors);
        return newErrors;
    };

    const handleSubmit = async () => {
        const newErrors = validatePasswords();
        
        // Check if there are any validation errors before proceeding
        if (newErrors.newPassword || newErrors.confirmPassword) {
            return; // Prevent submission if there are errors
        }

        try {
            setLoading(true)
            const headers = {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "application/json",
            };
            const response = await Instance.post('/api/v1/admin/change-password', {
                oldPassword,
                newPassword,
                confirmPassword
            }, { headers });

            if (response.status === 200) {
                message.success("Password changed successfully.");
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setErrors({ oldPassword: '', newPassword: '', confirmPassword: '' });
                setLoading(false);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const { message: errorMessage, errors: responseErrors } = error.response.data;

                if (responseErrors) {
                    setErrors({
                        oldPassword: responseErrors.oldPassword || '',
                        newPassword: responseErrors.newPassword || '',
                        confirmPassword: responseErrors.confirmPassword || '',
                    });
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        oldPassword: errorMessage || 'Old password is incorrect.',
                    }));
                }
            } else {
                message.error("An error occurred while changing the password.");
            }
        }
        finally{setLoading(false)}
    };

    return (
        <div>
            <h4 className="text-align-left d-flex pb-2 mt-4 mt-xl-0">Change Password</h4>
            <div className="col pb-4 settings-personalinfo-row">
                <label htmlFor="inputPasswordOld" className="form-label text-align-left d-flex pb-2">Old Password</label>
                <Input.Password
                    id="inputPasswordOld"
                    placeholder="Enter old password"
                    prefix={<CiLock style={{ width: "20px", height: "20px" }} />}
                    style={{ padding: "10px", borderRadius: "7px" }}
                    value={oldPassword}
                    onChange={handleOldPasswordChange}
                />
                {errors.oldPassword && <span className="error-message">{errors.oldPassword}</span>}
            </div>
            <div className="row pb-4 settings-personalinfo-row">
                <div className="col">
                    <label htmlFor="inputPasswordNew" className="form-label text-align-left d-flex pb-2">New Password</label>
                    <Input.Password
                        id="inputPasswordNew"
                        placeholder="Enter new password"
                        prefix={<CiLock style={{ width: "20px", height: "20px" }} />}
                        style={{ padding: "10px", borderRadius: "7px" }}
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                    />
                    {errors.newPassword && <span className="error-message">{errors.newPassword}</span>}
                </div>
                <div className="col">
                    <label htmlFor="inputPasswordConfirm" className="form-label text-align-left d-flex pb-2">Confirm Password</label>
                    <Input.Password
                        id="inputPasswordConfirm"
                        placeholder="Cofirm password"
                        prefix={<CiLock style={{ width: "20px", height: "20px" }} />}
                        style={{ padding: "10px", borderRadius: "7px" }}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                    />
                    {errors.confirmPassword && <span className="error-message">{errors.confirmPassword}</span>}
                </div>
            </div>
            <div className="d-flex justify-content-end pt-4 gap-2">
                <button
                    style={{ backgroundColor: "#f6f6f6", border: "none", borderRadius: "15px", padding: "10px 20px" }}
                    onClick={() => {
                        setOldPassword('');
                        setNewPassword('');
                        setConfirmPassword('');
                        setErrors({ oldPassword: '', newPassword: '', confirmPassword: '' });
                    }}
                >
                    Reset
                </button>
                <button
                    style={{ backgroundColor: "#1b3a57", border: "none", borderRadius: "15px", color: "#fff", padding: "10px 20px" }}
                    onClick={handleSubmit}
                >
                    {loading?(<Spin size="small"/>):(
                        "Save Changes"
                    )}
                    
                </button>
            </div>
        </div>
    );
};

export default ChangePassword;

