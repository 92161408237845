import React, { useState, useEffect } from "react";
import { Modal, Input, Button, Spin } from "antd";
import "./SignIn.css";
import NewPasswordModal from "./NewPasswordModal";
import Instance from "../../AxiosConfig";

const VerificationModal = ({ isVisible, onClose, responseMessage, email }) => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const [timer, setTimer] = useState(120);
  const [isNewPasswordModalVisible, setIsNewPasswordModalVisible] = useState(false);
  const [isVerificationSuccessModalVisible, setIsVerificationSuccessModalVisible] = useState(false);
  const [verificationResponse, setVerificationResponse] = useState("");
  const [verificationErrorResponse, setVerificationErrorResponse] = useState("");
  const [resendResponseMessage, setResendResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    if (isVisible) {
      setCode(new Array(6).fill(""));
      setTimer(120);
      setVerificationErrorResponse("");
      setResendResponseMessage("");
    }
  }, [isVisible]);

  const handleChange = (e, index) => {
    const newCode = [...code];
    newCode[index] = e.target.value;
    setCode(newCode);
    if (e.target.value && index < code.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    if (pasteData.length === code.length && /^\d+$/.test(pasteData)) {
      const newCode = pasteData.split("").slice(0, code.length);
      setCode(newCode);
      document.getElementById(`otp-input-${newCode.length - 1}`).focus();
    }
  };

  const handleResend = async () => {
    setTimer(120);
    setCode(new Array(6).fill("")); 
    try {
      const response = await Instance.post("/api/v1/forgotPassword/verifyMail", {
        email,
      });
      console.log("Form submitted", response.data);
      setResendResponseMessage("OTP has been resent.Check your Mail.");
      setVerificationErrorResponse("");
    } catch (error) {
      console.error("Error during form submission:", error);
      if (error.response) {
        console.error("Error response:", error.response);
        let errorMessage = "Resend failed!";
        if (error.response.data && error.response.data.message) {
          const serverMessage = error.response.data.message;
          if (serverMessage.includes(":")) {
            errorMessage = serverMessage.split(":")[0];
          } else {
            errorMessage = serverMessage;
          }
        }
        setVerificationErrorResponse(errorMessage);
        setResendResponseMessage("");
      } else {
        setVerificationErrorResponse("Resend failed!");
        setResendResponseMessage("");
      }
    }
  };

  const handleContinue = async () => {
    const verificationCode = code.join("");
    setLoading(true);
    try {
      const response = await Instance.post(
        `/api/v1/forgotPassword/verifyOtp/${email}`,
        {
          otp: verificationCode,
          email: email,
        }
      );
      console.log("Verification submitted", response.data);
      setVerificationResponse(response.data);
      onClose();
      setIsVerificationSuccessModalVisible(true);
    } catch (error) {
      console.error("Error during verification submission:", error);
      if (error.response && error.response.data && error.response.data.message) {
        setVerificationErrorResponse(error.response.data.message);
      } else {
        setVerificationErrorResponse("An error occurred during verification.");
      }
    }
    setLoading(false);
  };

  const handleVerificationSuccessModalClose = () => {
    setIsVerificationSuccessModalVisible(false);
    setIsNewPasswordModalVisible(true);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };

  return (
    <>
      <Modal visible={isVisible} onCancel={onClose} footer={null}>
        <div className="d-flex justify-content-center flex-column forgot-password-div">
          <h2>Verification</h2>
          {verificationErrorResponse ? (
            <p style={{ color: "red" }}>{verificationErrorResponse}</p>
          ) : resendResponseMessage ? (
            <p style={{ color: "green" }}>{resendResponseMessage}</p>
          ) : (
            responseMessage && <p>{responseMessage}</p>
          )}
          <p>Enter the 6-digit code that you received on your email.</p>
          <div className="code-inputs d-flex gap-4" onPaste={handlePaste}>
            {code.map((otp, idx) => (
              <Input
                key={idx}
                id={`otp-input-${idx}`}
                type="text"
                maxLength="1"
                value={otp}
                onChange={(e) => handleChange(e, idx)}
                style={{ width: "50px", height: "50px", textAlign: "center" }}
              />
            ))}
          </div>
          <div className="timer d-flex justify-content-center">
            <span style={{ color: "red" }}>{formatTime(timer)}</span>
          </div>
          <Button
            key="submit"
            type="primary"
            className="continue-button"
            onClick={handleContinue}
            disabled={loading}
          >
            {loading ? <Spin /> : "Verify"}
          </Button>
          <p className="text-center pt-2">
            If you didn’t receive a code!
            <Button type="link" onClick={handleResend} disabled={timer !== 0}>
              Resend
            </Button>
          </p>
        </div>
      </Modal>

      <Modal
        visible={isVerificationSuccessModalVisible}
        onCancel={handleVerificationSuccessModalClose}
        footer={null}
      >
        <div className="d-flex justify-content-center flex-column forgot-password-div">
          <h2 className="text-center">Verification Successful</h2>
          <p className="text-center">{verificationResponse}</p>
          <Button
            key="submit"
            type="primary"
            className="continue-button"
            onClick={handleVerificationSuccessModalClose}
          >
            Continue
          </Button>
        </div>
      </Modal>

      <NewPasswordModal
        isVisible={isNewPasswordModalVisible}
        onClose={() => setIsNewPasswordModalVisible(false)}
        email={email}
      />
    </>
  );
};

export default VerificationModal;
