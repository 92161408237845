import React, { useEffect, useState } from 'react';
import './HomeBanner.css';
import cement from '../../Assets/SKImages/our-product-cement.png';
import steel from '../../Assets/SKImages/our-product-steel.png';
import { useNavigate } from 'react-router-dom';
import Instance from '../../AxiosConfig';

export const OurProducts = () => {
    // const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1600);

    // useEffect(() => {
    //   const handleResize = () => {
    //     setIsLargeScreen(window.innerWidth >= 1600);
    //   };

    //   window.addEventListener("resize", handleResize);
    //   return () => {
    //     window.removeEventListener("resize", handleResize);
    //   };
    // }, []);

    const [products, setProducts] = useState({});


    const navigate = useNavigate();

    const handleShopButton = (productType) => {
        navigate(`/shop?type=${productType}`);
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await Instance.get("/api/v1/products/grouped-by-category");
                setProducts(response.data);
                console.log("All PRODUCTS:", response.data)

                // setLoading(false);
                console.log("fetched all products are:", response.data)
            } catch (error) {
                console.error("Error fetching products:", error);
                // setLoading(false);
            }
        };
        fetchProducts();
    }, []);
    const cementProducts = products.Cement;
    const steelProducts = products.Steel; // Accessing the array for Cement products
    const isCementDisabled = cementProducts?.length > 0 ? cementProducts[0].category.disabled : true;
    const isSteelDisabled = steelProducts?.length > 0 ? steelProducts[0].category.disabled : true;


    return (
        <div className='container our-product-container'>
            <h2 className='our-product-heading justify-content-center d-flex'>Our Products Range</h2>
            <div className="row gap-4 d-flex justify-content-center m-2">
                {cementProducts?.length > 0 && cementProducts[0].category.categoryTitle === "Cement" && !isCementDisabled ? (
                    <div className="col-lg-5 gap-4 our-product-card d-flex gap-4 ">
                        <div className='col-lg-5'>
                        </div>
                        <div className='col-lg-7'>
                            <div className='our-product-text '>
                                <div className='d-flex flex-column home-ourproduct-div'>
                                    <div className='product-name '>
                                        <h2>Cement</h2>
                                        <p>Price Today</p>
                                    </div>
                                    <div className='home-ourproduct-shopbutton'>
                                        <button className="faq-get-in-touch-button button-product mt-4 media-button home-shop-now-button" onClick={() => handleShopButton('cement')}>Shop Now</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                ) : null}

                {steelProducts?.length > 0 && steelProducts[0].category.categoryTitle === "Steel" && !isSteelDisabled ? (
                    <div className="col-lg-5 gap-4 our-product-card1 d-flex ">
                        <div className='col-lg-5'>

                        </div>
                        <div className='col-lg-7'>
                            <div className='our-product-text'>
                                <div className='d-flex flex-column home-ourproduct-div'>
                                    <div className='product-name'>
                                        <h2>Steel</h2>
                                        <p>Price Today</p>
                                    </div>
                                    <div className='home-ourproduct-shopbutton'><button className="faq-get-in-touch-button  button-product mt-4 media-button" onClick={() => handleShopButton('steel')}>Shop Now</button></div>
                                </div>
                            </div>
                        </div>

                    </div>
                ) : null}
            </div>
        </div>
    );
};
