import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Instance from "../../AxiosConfig";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Modal, Button, Spin, Input, message } from "antd";
import "./SignIn.css";
import formImg from "../../Assets/SKImages/form-img.png";
import { register} from "../../states/reducers/AuthSlice"; 
import { useDispatch } from "react-redux";
import { addItem,addItems } from '../../states/reducers/AddToCartSlice';
import { addWishlists } from '../../states/reducers/wishlistSlice';
const Register = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formValues, setFormValues] = useState({
    fullName: "",
    mobile: "",
    email: "",
    password: "",
  });
  const dispatch=useDispatch
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const [verificationErrorResponse, setVerificationErrorResponse] =
    useState("");
  const [
    isVerificationSuccessModalVisible,
    setIsVerificationSuccessModalVisible,
  ] = useState(false);
  const [resendResponseMessage, setResendResponseMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [verificationResponse, setVerificationResponse] = useState("");
  const [timer, setTimer] = useState(120);
  const [loading, setLoading] = useState(false);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };
  const [code, setCode] = useState(new Array(6).fill(""));
  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    if (pasteData.length === code.length && /^\d+$/.test(pasteData)) {
      const newCode = pasteData.split("").slice(0, code.length);
      setCode(newCode);
      document.getElementById(`otp-input-${newCode.length - 1}`).focus();
    }
  };

  useEffect(() => {
    let interval;
    if (showModal) {
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : prevTimer));
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [showModal]);
  const handleOtpChange = (e, index) => {
    const newCode = [...code];
    newCode[index] = e.target.value;
    setCode(newCode);
    if (e.target.value && index < code.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/, ''); // Remove leading spaces only
  
    if (name === "email") {
      // Remove all spaces from email value
      const emailValue = trimmedValue.replace(/\s+/g, '');
      let emailError = "";
  
      // Check if email starts with a letter
      if (/^[0-9]/.test(emailValue)) {
        emailError = "Email should not start with a number";
      } else if (/[A-Z]/.test(emailValue)) {
        emailError = "Email should not contain uppercase letters";
      } else if (!/@/.test(emailValue) || !/\./.test(emailValue)) {
        emailError = "Email must contain '@' and '.'";
      } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(emailValue)) {
        emailError = "Email is invalid";
      }
  
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: emailError,
      }));
      setFormValues({ ...formValues, [name]: emailValue });
    } else if (name === "fullName") {
      const textValue = trimmedValue.replace(/[^a-zA-Z\s]/g, "");
      let fullNameError = "";
      if (textValue.length === 0) {
        fullNameError = "Full Name is required";
      } else if (textValue.length < 3) {
        fullNameError = "Full Name must be at least 3 characters";
      } else if (textValue.length > 33) {
        fullNameError = "Full Name must not exceed 16 characters";
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        fullName: fullNameError,
      }));
      setFormValues({ ...formValues, [name]: textValue });
    } else if (name === "mobile") {
      const numericValue = trimmedValue.replace(/[^0-9]/g, "");
      let mobileError = "";
      if (!numericValue) {
        mobileError = "Mobile Number is required";
      } else if (/^0+$/.test(numericValue)) {
        mobileError = "Mobile Number cannot be all zeros";
      } else if (!/^[4-9]\d{9}$/.test(numericValue)) {
        mobileError = "The Phone number should be start from 4 to 9 digits and Number must be exactly 10 digits";
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: mobileError,
      }));
      setFormValues({ ...formValues, [name]: numericValue });
    } else if (name === "password") {
      let passwordError = "";
      if (trimmedValue.length < 8) {
        passwordError = "Password must be at least 8 characters";
      }else if (trimmedValue.length > 16) {
        passwordError = "Password should not exceed 16 characters";
      }else if (!/[A-Z]/.test(trimmedValue)) {
        passwordError = "Password must contain at least one uppercase letter";
      } else if (!/[a-z]/.test(trimmedValue)) {
        passwordError = "Password must contain at least one lowercase letter";
      } else if (!/[0-9]/.test(trimmedValue)) {
        passwordError = "Password must contain at least one number";
      } else if (!/[!@#$%^&*]/.test(trimmedValue)) {
        passwordError = "Password must contain at least one special character";
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: passwordError,
      }));
      setFormValues({ ...formValues, [name]: trimmedValue });
    } else {
      setFormValues({ ...formValues, [name]: trimmedValue });
    }
  };


  const validate = () => {
    const newErrors = {};
    if (!formValues.fullName) newErrors.fullName = "Full Name is required";
    else if (formValues.fullName.length < 3) newErrors.fullName = "Full Name must be at least 3 characters";
    else if (formValues.fullName.length > 16) newErrors.fullName = "Full Name must not exceed 16 characters";
    if (!formValues.mobile) newErrors.mobile = "Mobile Number is required";
    else if (!/^[4-9]\d{9}$/.test(formValues.mobile)) newErrors.mobile = "Mobile Number must be exactly 10 digits and start with a digit between 4 and 9";
    else if (/^0+$/.test(formValues.mobile)) newErrors.mobile = "Mobile Number cannot be all zeros";
    if (!formValues.email) newErrors.email = "Email is required";
    else if (/^\d/.test(formValues.email)) newErrors.email = "Email should not start with a number";
    else if (!formValues.email.endsWith('@gmail.com')) newErrors.email = " Gmail addresses are not allowed";
    else if (/[A-Z]/.test(formValues.email)) newErrors.email = "Email should not contain uppercase letters";
    else if (!/@/.test(formValues.email) || !/\./.test(formValues.email)) newErrors.email = "Email must contain '@' and '.'";
    else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(formValues.email)) newErrors.email = "Email is invalid";
    if (!formValues.password) newErrors.password = "Password is required";
    else if (formValues.password.length < 8) newErrors.password = "Password must be at least 8 characters";
    else if (!/[A-Z]/.test(formValues.password)) newErrors.password = "Password must contain at least one uppercase letter";
    else if (!/[a-z]/.test(formValues.password)) newErrors.password = "Password must contain at least one lowercase letter";
    else if (!/[0-9]/.test(formValues.password)) newErrors.password = "Password must contain at least one number";
    else if (!/[!@#$%^&*]/.test(formValues.password)) newErrors.password = "Password must contain at least one special character";
    return newErrors;
};
const fetchCartCount = async () => {
  try {
    let sessionId = sessionStorage.getItem("sessionId");
    const response = await Instance.get(
      `/api/v1/cart/?sessionId=${sessionId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(addItems(response.data.cartItems))
    }
   
  } catch (error) {
    console.error("Error fetching cart count:", error);
     }
};

useEffect(() => {
  fetchCartCount(dispatch);
},[dispatch]);
const fetchWishlistData = async () => {
  try {
    let sessionId = sessionStorage.getItem('sessionId');
    const url = `/api/v1/wishlist/?sessionId=${sessionId}`;
    const response = await Instance.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(addWishlists(response.data));
      console.log("wishlistData",response.data)
    }
  } catch (error) {
    console.error(error?.response?.data?.message || "An error occurred");
  }
};
useEffect(() => {
  fetchWishlistData(dispatch); 
}, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      console.log("Form values before submission:", formValues);
      try {
        setLoading(true);
        const emailToSend = formValues.email;
        if (emailToSend != null) {
          const url = `/api/v1/auth/sendVerificationOtp?email=${encodeURIComponent(
            emailToSend
          )}`;
          const response = await Instance.post(url);
          console.log("OTP send response:", response);
          setResponseMessage(response?.data);
        }
        setShowModal(true);
      } catch (error) {
        console.error("Error during OTP request:", error);
        if (error.response) {
          console.error("Error response:", error.response);
          let errorMessage = "OTP request failed!";
          if (error.response?.data && error.response?.data.message) {
            const serverMessage = error.response?.data.message;
            if (serverMessage.includes(":")) {
              errorMessage = serverMessage.split(":")[0];
            } else {
              errorMessage = serverMessage;
            }
          }
          toast.error(errorMessage);
        } else {
          toast.error("OTP request failed!");
        }
      }
      setLoading(false)
    } else {
      toast.error("Please fix the errors in the form.");
    }
  };

  const handleModalClose = () => {
    setCode(new Array(6).fill(""));
    setTimer(120);
    setShowModal(false);
  };

  const handleVerificationSuccessModalClose = () => {
    setIsVerificationSuccessModalVisible(false);
  };

  const handleVerify = async () => {
    try {
      const otp = code.join("");
      const email = formValues.email;
      const response = await Instance.post(`/api/v1/auth/verifyOtp/${email}`, {
        otp,
      });
      console.log("Verification response:", response);

      setShowModal(false);
      setIsVerificationSuccessModalVisible(true);
      setVerificationResponse(response?.data);
    } catch (error) {
      console.error(
        "Error during OTP verification:",
        error.response?.data.message
      );
      setVerificationErrorResponse(error.response?.data.message);
    }
  };

  const handleResend = async () => {
    setCode(new Array(6).fill("")); 
    try {
      const email = formValues.email;
      const url = `/api/v1/auth/sendVerificationOtp?email=${encodeURIComponent(
        email
      )}`;
      const response = await Instance.post(url);
      console.log("Resend OTP response:", response);
      setResendResponseMessage("OTP resent successfully.");
      setCode(new Array(6).fill(""));
      setTimer(120);
    } catch (error) {
      console.error("Error resending OTP:", error.response?.data.message);
    }
  };
  const handleContinue = async () => {
    setLoading(true);
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const response = await Instance.post(`/api/v1/auth/register${sessionId ? `?sessionId=${sessionId}` : ''}`, formValues);
      console.log("Registration response:", response);
      const token = response?.data?.jwtToken;
      localStorage.setItem("authToken", token);
      sessionStorage.removeItem("sessionId");
      navigate("/");
      dispatch(register());
      setIsVerificationSuccessModalVisible(false);
    } catch (error) {
      console.error("Error during registration:", error.response?.data.message);
    }
    setLoading(false);
  };

  const handleLoginClick = () => {
    navigate("/signin");
  };

  return (
    <div className="sign-page">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "100px" }}
      />
      <div className="form-container">
        <div className="col-lg-9 left-section">
          <h1>Builderneed</h1>
          <img
            src={formImg}
            alt="Construction Materials"
            className="image-container"
          />
        </div>
        <div className="col-lg-3 right-section">
          <form className="login-form" onSubmit={handleSubmit}>
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              placeholder="Enter full name"
              value={formValues.fullName}
              onChange={handleChange}
              className={errors.fullName ? "invalid" : ""}
            />
              {errors.fullName && (
              <span className="error">{errors.fullName}</span>
            )}
            <label htmlFor="mobile">Phone No</label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              placeholder="Enter phone number"
              maxLength={10}
              value={formValues.mobile}
              onChange={handleChange}
              className={errors.mobile ? "invalid" : ""}
            />
            {errors.mobile && <span className="error">{errors.mobile}</span>}

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="example@gmail.com"
              value={formValues.email}
              onChange={handleChange}
              className={errors.email ? "invalid" : ""}
            />
            {errors.email && <span className="error">{errors.email}</span>}

            <label htmlFor="password">Password</label>
            <div className="password-container">
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password"
                value={formValues.password}
                onChange={handleChange}
                className={errors.password ? "invalid" : ""}
              />
              <button
                type="button"
                className="password-toggle"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </button>
            </div>
            {errors.password && (
              <span className="error">{errors.password}</span>
            )}

            {/* <div className="form-options">
              <label className="d-flex align-items-center gap-2">
                <input type="checkbox" name="remember" />
                Remember me
              </label>
            </div> */}

            <div className="d-flex flex-column button-container gap-3">             
              {/* <button
                type="button"
                className="register-btn"
                onClick={handleLoginClick}
              >
                Login
              </button> */}
              <button
                type="submit"
                className="login-btn mt-3 w-100"
                id="register-button"
                onClick={handleSubmit}
              >
                {loading ? <Spin /> : "Register"}

              </button>
              <p className="text-allign-left">Already have an account?<span>&nbsp;<a href="/signin">Login here</a></span></p>
            </div>
          </form>
        </div>
      </div>

      <Modal onCancel={handleModalClose} visible={showModal} footer={null}>
        <div className="d-flex justify-content-center flex-column forgot-password-div">
          <h2>Verification</h2>
          {verificationErrorResponse ? (
            <p style={{ color: "red" }}>{verificationErrorResponse}</p>
          ) : resendResponseMessage ? (
            <p style={{ color: "green" }}>{resendResponseMessage}</p>
          ) : (
            responseMessage && <p>{responseMessage}</p>
          )}
          <p>Enter the 6-digit code that you received on your email.</p>
          <div className="code-inputs d-flex gap-4" onPaste={handlePaste}>
            {code.map((otp, idx) => (
              <Input
                key={idx}
                id={`otp-input-${idx}`}
                type="text"
                maxLength="1"
                value={otp}
                onChange={(e) => handleOtpChange(e, idx)}
                style={{ width: "50px", height: "50px", textAlign: "center" }}
              />
            ))}
          </div>
          <div className="timer d-flex justify-content-center">
            <span style={{ color: "red" }}>{formatTime(timer)}</span>
          </div>
          <Button
            key="submit"
            type="primary"
            className="continue-button"
            onClick={handleVerify}
            disabled={loading}
          >
            {loading ? <Spin /> : "Verify"}
          </Button>
          <p className="text-center pt-2">
            If you didn’t receive a code!
            <Button type="link" disabled={timer !== 0} onClick={handleResend}>
              Resend
            </Button>
          </p>
        </div>
      </Modal>

      <Modal
        visible={isVerificationSuccessModalVisible}
        onCancel={handleVerificationSuccessModalClose}
        footer={null}
      >
        <div className="d-flex justify-content-center flex-column forgot-password-div">
          <CheckCircleOutlined
            style={{ color: "#1b3a57", fontSize: "78px" }}
            className="d-flex justify-content-center"
          />
          <h2 className="text-center">{verificationResponse}</h2>
          <Button
            key="submit"
            type="primary"
            className="continue-button"
            onClick={handleContinue}
            disabled={loading}
          >
            {loading ? <Spin /> : "Continue"}
          </Button>
        </div>
      </Modal>
    </div>
  );
  };

export default Register;