import React, {useEffect, useState} from "react";
import icon1 from "../../Assets/icons/customer-satisfaction-icon.png";
import icon2 from "../../Assets/icons/file-diamond-icon.png";
import icon3 from "../../Assets/icons/integrity-icon.png";
import icon4 from "../../Assets/icons/innovation-icon.png";
import curve from "../../Assets/icons/curve-line.png"
import MissionImg from "../../Assets/SKImages/missionImage.png"

import { ArrowRightOutlined } from "@ant-design/icons";
export const OurValues = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 2400);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 2400);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
      <div className='container our-values-container'>
        <div className='row  mission-container'>
            <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-start col-xxl-8">
                <h2>Mission</h2>
                <p>Our mission is to deliver the highest quality cement and steel products to our customers, ensuring their
                     construction projects stand the test of time. We are dedicated to supporting the construction community
                      by providing materials that enhance durability and strength. By adhering to rigorous quality standards, 
                      we aim to offer products that contribute to sustainable building practices. Our commitment extends to innovation 
                      and continuous improvement, ensuring that our customers always receive the best solutions for their needs. 
                      At Builderneed, we believe in building not just structures, but lasting relationships based on trust and excellence.
                </p>
                
                {/* <button className="explore-button">Read More</button> */}
                
            </div>
            <div className='col-lg-6 col-xxl-4'>
                <div>
                    <img src={MissionImg} className='mission-img' alt="" />
                </div>
            </div>
        </div>
      <div className="row mt-5 our-values-tab">
        <div className="col-lg-6 col-md-12 second-our-value-tab">
          <div className="d-flex flex-wrap gap-4 our-values-tab-media">
            <div className="our-value-cards">
              <img src={icon2} alt="" />
              <h3>Quality</h3>
              <p>
                We are committed to offering products that meet the highest
                quality standards, ensuring your projects are built to last
              </p>
              {/* <a href="#">
                Learn More <ArrowRightOutlined />
              </a> */}
            </div>
            <div className="our-value-cards">
              <img src={icon1} alt="" />
              <h3>Customer Satisfaction</h3>
              <p>
                Our customers are at the heart of everything we do. We strive to
                exceed their expectations with excellent service and support.
              </p>
              {/* <a href="#">
                Learn More <ArrowRightOutlined />
              </a> */}
            </div>
            <div className="our-value-cards">
              <img src={icon3} alt="" />
              <h3>Integrity</h3>
              <p>
                We conduct our business with the utmost integrity, ensuring
                transparency and honesty in all our dealings.
              </p>
              {/* <a href="#">
                Learn More <ArrowRightOutlined />
              </a> */}
            </div>
            <div className="our-value-cards">
              <img src={icon4} alt="" />
              <h3>Innovation</h3>
              <p>
                We continuously seek innovative solutions to improve our
                products and services, staying ahead of industry trends.
              </p>
              {/* <a href="#">
                Learn More <ArrowRightOutlined />
              </a> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6 our-values-right second-our-value-tab">
            <h2>Our Values </h2>
            <p>At Builderneed, we prioritize delivering top-quality products to ensure your projects are durable and reliable. 
                Customer satisfaction is at the core of our operations, and we strive to exceed expectations with exceptional service and support. Integrity and transparency guide all our business dealings, fostering trust with our customers and partners. We embrace innovation to stay ahead of industry trends and are committed to sustainable practices that contribute to a greener future. Our dedicated team works collaboratively to achieve excellence in everything we do.</p>
        </div>
      </div>
    </div>
  );
};
