import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Protect = ({ Component }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("authToken");    
    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token, navigate]);

    return token ? <Component /> : null;
};

export default Protect;
