import React, { useState, useEffect } from "react";
import Instance from "../../AxiosConfig";
import { message, Spin } from "antd";
import { Rate, Pagination, Button, Slider, Menu, Dropdown } from "antd";
import {
  SearchOutlined,
  ShoppingCartOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  HeartOutlined,
  HeartFilled,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import INRrupee from "./INRrupee";
import { addWishlist, addWishlists, removeWishlist } from "../../states/reducers/wishlistSlice";
import { addItem, addItems } from "../../states/reducers/AddToCartSlice";
import { useDispatch } from "react-redux";

const FilteredProducts = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const handleCardClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };
  const productType = queryParams.get("type") || "null";
  const [position] = useState("start");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(5000);
  const [priceRangeVisible, setPriceRangeVisible] = useState(true);
  const [categoriesVisible, setCategoriesVisible] = useState(true);
  const [brandsVisible, setBrandsVisible] = useState(true);
  const [ratingsVisible, setRatingsVisible] = useState(true);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(9);
  const [brands, setBrands] = useState([]);
  const handleMenuClick = (e) => {
    // console.log("Price range selected:", e.key);
  };
  const handleVisibleChange = (flag) => {
    setDropdownVisible(flag);
  };
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [minRating, setMinRating] = useState("");
  const [wishlistData, setWishlistData] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [allProductsData, setAllProductsData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  // Temporary filter values
  const [tempSelectedCategories, setTempSelectedCategories] = useState([]);
  const [tempSelectedBrands, setTempSelectedBrands] = useState([]);
  const [tempMinPrice, setTempMinPrice] = useState(0);
  const [tempMaxPrice, setTempMaxPrice] = useState(5000);
  const [tempMinRatings, setTempMinRatings] = useState([]);

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="0-100">0-100</Menu.Item>
      <Menu.Item key="100-200">100-200</Menu.Item>
      <Menu.Item key="200-300">200-300</Menu.Item>
    </Menu>
  );
  // const onChange = (value) => {
  //   setMinPrice(value[0]);
  //   setMaxPrice(value[1]);
  // };
  const togglePriceRange = () => {
    setPriceRangeVisible(!priceRangeVisible);
  };
  const toggleCategories = () => {
    setCategoriesVisible(!categoriesVisible);
  };
  const toggleBrands = () => {
    setBrandsVisible(!brandsVisible);
  };
  const toggleRatings = () => {
    setRatingsVisible(!ratingsVisible);
  };
  const [liked, setLiked] = useState([]);
  const toggleLike = (index, productId) => {
    const newLiked = [...liked];
    newLiked[index] = !newLiked[index];
    setLiked(newLiked);
    if (newLiked[index]) {
      addToWishlist(productId);
    }
  };
  const query = new URLSearchParams(location.search).get("query");
  useEffect(() => {
    const fetchAllSearchProducts = async () => {
      // setSelectedCategories([]);
      // setSelectedBrands([]);
      setFilteredProducts([]);
      // setAllProductsData([]);

      setLoading(true);
      try {
        const response = await Instance.get(`/api/v1/products/search/${query}`);
        if (response.status === 200) {
          setCurrentPage(1);
          setProducts(response.data);
          setTotalItems(response.data?.length);
          console.log("query search", response.data)
        }
      } catch (error) {
        console.error(error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchAllSearchProducts();
  }, [query]);

  const fetchAllProducts = async () => {
    setProducts([]);
    setLoading(true);
    try {
      const url = `/api/v1/products/all`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        //  console.log("asdfg",response.data)
        setAllProductsData(response.data);
        setFilteredProducts(response.data);
        //  setTotalItems(response.data.length);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const filterProducts = () => {
    setAllProductsData(allProductsData)
    let filtered = [...allProductsData];
    console.log("FILTERED", filtered)

    if (selectedCategories.length > 0) {
      filtered = filtered.filter((product) =>
        selectedCategories.includes(product.categoryTitle)
      );
    }

    if (selectedBrands.length > 0) {
      filtered = filtered.filter((product) =>
        selectedBrands.includes(product.brand)
      );
    }

    if (minPrice || maxPrice) {
      filtered = filtered.filter(
        (product) =>
          product.discountedPrice >= minPrice &&
          product.discountedPrice <= maxPrice
      );
    }

    if (tempMinRatings.length > 0) {
      // Only include products with a rating that matches exactly one of the selected ratings
      filtered = filtered.filter(product =>
        tempMinRatings.includes(Math.floor(product.numRatings))
      );
    }

    setFilteredProducts(filtered);
    console.log("filteredProducts", filteredProducts);
    setTotalItems(filtered?.length);
    setCurrentPage(1);
  };

  useEffect(() => {
    filterProducts();
    setAllProductsData(allProductsData)
  }, [selectedCategories, selectedBrands, minPrice, maxPrice]);

  const fetchWishlistData = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const url = `/api/v1/wishlist/?sessionId=${sessionId}`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        setWishlistData(response.data);
        dispatch(addWishlists(response.data))
        // console.log("wishlistData",response.data)
      }
    } catch (error) {
      console.error(error?.response?.data?.message || "An error occurred");
    }
  };
  const addToWishlist = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem("sessionId", sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem("sessionId");
      }
      const url = `/api/v1/wishlist/products/${productId}${sessionId ? `?sessionId=${sessionId}` : ""
        }`;
      const response = await Instance.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        message.success("Product added to wishlist successfully");
        dispatch(addWishlist(response.data))
        fetchWishlistData();
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
        "An error occurred while adding to wishlist"
      );
    }
  };
  const handleCategoryChange = (categoryTitle) => {
    setTempSelectedBrands([]);

    if (tempSelectedCategories === categoryTitle) {
      setTempSelectedCategories(null);
      setFilteredBrands(brands);
    } else {
      setTempSelectedCategories(categoryTitle);
      filterBrandsByCategory(categoryTitle);
    }
    // console.log("tempselectedCateory:",tempSelectedCategories)
    console.log("tempselectedcategoryTitle:", categoryTitle)
  };

  const handleBrandChange = (brand) => {
    setTempSelectedBrands((prev) =>
      prev.includes(brand) ? prev.filter((b) => b !== brand) : [...prev, brand]
    );
  };

  const filterBrandsByCategory = (categoryTitle) => {
    const filtered = brands.filter((brand) =>
      brand.categories.includes(categoryTitle)
    );
    setFilteredBrands(filtered);
  };

  // Initially, show all brands
  useEffect(() => {
    setFilteredBrands(brands);
  }, [brands]);

  const handleRatingChange = (rating) => {
    let updatedRatings = [...tempMinRatings];
    if (updatedRatings.includes(rating)) {
      updatedRatings = updatedRatings.filter(r => r !== rating); // Remove the rating if it is already selected
    } else {
      updatedRatings.push(rating); // Add the selected rating
    }
    setTempMinRatings(updatedRatings); // Update the state with the new ratings
  };

  const onChange = (value) => {
    setTempMinPrice(value[0]);
    setTempMaxPrice(value[1]);
  };

  const handleMinPriceChange = (e) => {
    const value = Math.min(
      Number(e.target.value.replace(/[^0-9]/g, "")),
      tempMaxPrice - 1
    );
    setTempMinPrice(value);
  };
  const handleFocus = (e, tempMaxPrice) => {
    if (tempMaxPrice === 1) {
      e.target.value = '';
    } else {
      e.target.value = e.target.value.replace(/[₹,]/g, '');
    }
  };
  

  const handleMaxPriceChange = (e) => {
    const value = Math.max(
      Number(e.target.value.replace(/[^0-9]/g, "")),
      tempMinPrice + 1
    );
    setTempMaxPrice(value);
  };
  const handleBlur = (e, tempMinPrice) => {
    const value = Number(e.target.value.replace(/[^0-9]/g, ""));
    if (!value || isNaN(value)) {
      setTempMaxPrice(tempMinPrice + 1);
    } else {
      setTempMaxPrice(value);
    }
  };
  const sliderMax = tempMaxPrice > 5000 ? tempMaxPrice : 5000;


  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const fetchCartCount = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.get(
        `/api/v1/cart/?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(addItems(response.data.cartItems))
      }

    } catch (error) {
      console.error("Error fetching cart count:", error);
    }
  };
  const addToCart = async (productId, cartItemDtos = []) => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem("sessionId", sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem("sessionId");
      }
      const url = `api/v1/cart/add/${productId}${sessionId ? `?sessionId=${sessionId}` : ""
        }`;
      const response = await Instance.post(url, cartItemDtos, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        message.success(response.data.message);
        dispatch(addItem(response.data))
        fetchCartCount()
        // console.log(`Product ${productId} added to sessionId: ${sessionId}`);
        console.log(response);
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
        "An error occurred while adding to cart"
      );
      console.log("asdfg", error?.response);
    }
  };

  const removeFromWishlist = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const url = `/api/v1/wishlist/product/${productId}?sessionId=${sessionId}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        message.success(response?.data?.message);
        dispatch(removeWishlist(productId))
        fetchWishlistData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };
  useEffect(() => {
    fetchWishlistData(dispatch);
  }, [dispatch]);
  const generateSessionId = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const getBrands = async () => {
    setLoading(true);
    try {
      const url = `/api/v1/brand/`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        console.log("Brands fetched:", response.data);
        setBrands(response.data);
      } else {
        setBrands([]);
      }
    } catch (error) {
      console.error("Failed to fetch brands:", error);
      setBrands([]);
    }
    setLoading(false);
  };
  const handleAddToCart = (e, product) => {
    e.stopPropagation();
    if (product.categoryTitle.toLowerCase() === "steel") {
      handleCardClick(product.productId);
    } else {
      addToCart(product.productId);
    }
  };

  const handleApplyFilters = () => {
    setProducts([]);


    if (window.innerWidth > 768) {

      window.scrollTo({
        top: 150,
        behavior: 'smooth'
      });
    } else {

      window.scrollTo({
        top: window.pageYOffset + 300,
        behavior: 'smooth'
      });
    }
    if (!tempSelectedCategories) {
      setSelectedCategories([]);
      setSelectedBrands([]);
      setFilteredProducts(allProductsData);
      // setFiltersApplied(false);
    } else {
      setSelectedCategories(tempSelectedCategories);
      setSelectedBrands(tempSelectedBrands);
      setMinPrice(tempMinPrice);
      setMaxPrice(tempMaxPrice);
      setFiltersApplied(true);
      // setAllProductsData(allProductsData)
      // Apply all filters, including ratings
      filterProducts();
      setFiltersApplied(true);
    }
  };

  useEffect(() => {
    getBrands();
    getProductCategory();
    fetchAllProducts();
  }, []);

  const getProductCategory = async () => {
    setLoading(true);
    try {
      const url = `/api/v1/categories/`;
      const response = await Instance.get(url);
      if (response.status === 200) {
        setCategories(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
    setLoading(false);
  };

  const displayedProducts = filtersApplied
    ? filteredProducts.length > 0
      ? filteredProducts
      : products
    : products;

  const paginatedProducts = displayedProducts.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const renderCard = (card, index) => {
    const isLiked = wishlistData.some(
      (item) => item.productId === card.productId
    );

    const toggleLike = (e) => {
      e.stopPropagation();
      if (isLiked) {
        removeFromWishlist(card.productId);
      } else {
        addToWishlist(card.productId);
      }
    };
    return (
      <div
        key={index}
        className="products-card-main"
        style={{ width: "260px" }}
        onClick={() => handleCardClick(card.productId)}
      >
        <div className="Heart-outlined" onClick={toggleLike}>
          {isLiked ? (
            <HeartFilled style={{ color: "red" }} />
          ) : (
            <HeartOutlined />
          )}
        </div>

        <div>
          <img
            src={`data:image/jpeg;base64,${card.imageData}`}
            alt={card.title}
            style={{ width: "200px", height: "200px" }}
          />
        </div>
        <div className="products-card-body">
          <Rate
            disabled
            allowHalf
            value={card.numRatings}
            style={{
              display: "flex",
              justifyContent: "start",
              paddingTop: "20px",
            }}
          />
          <h2>{card.title}</h2>
          <h3>{card.description}</h3>
          <div className="product-price d-flex gap-2">
            <h3 className="price"><INRrupee />{card.discountedPrice}</h3>
            <span className="original-price"><INRrupee />{card.price}</span>
            <span className="home-product-detail-discount">
              {card?.discountPercentage}% off
            </span>
          </div>
          <p className="product-brand">
            <span>Brand:</span> {card.brand}
          </p>
          <button
            className="add-to-cart-button mt-3 "
            onClick={(e) => handleAddToCart(e, card)}
          >
            <ShoppingCartOutlined className="pe-2" />
            Add To Cart
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className="container shop-container">
      <div className="row shop-product-row">
        <div className="col-lg-3">
          <div className="sticky-product-sidebar">
            <div className="d-flex justify-content-between">
              <h4 className="form-label d-flex justify-content-start">
                Price Range
              </h4>
              <div onClick={togglePriceRange} style={{ cursor: "pointer" }}>
                {priceRangeVisible ? (
                  <CaretUpOutlined />
                ) : (
                  <CaretDownOutlined />
                )}
              </div>
            </div>
            {priceRangeVisible && (
              <div>
                <Slider
                  range
                  step={100}
                  min={0}
                  max={sliderMax}
                  value={[tempMinPrice, tempMaxPrice]}
                  onChange={onChange}
                  className="price-slider"
                />
                <div className="price-inputs d-flex justify-content-between">
                  <input
                    type="text"
                    value={`₹${tempMinPrice}`}
                    onChange={handleMinPriceChange}
                    className="price-input"
                  />
                  <span> - </span>
                  <input
                    type="text"
                    value={`₹${tempMaxPrice}`}
                    onFocus={(e) => handleFocus(e, tempMaxPrice)}
                    onChange={handleMaxPriceChange}
                    onBlur={(e) => handleBlur(e, tempMinPrice)}
                    className="price-input"
                  />
                </div>
              </div>
            )}
            <div className="filter-section">
              <div className="d-flex justify-content-between">
                <h4>Categories</h4>
                <div onClick={toggleCategories} style={{ cursor: "pointer" }}>
                  {categoriesVisible ? (
                    <CaretUpOutlined />
                  ) : (
                    <CaretDownOutlined />
                  )}
                </div>
              </div>
              {categoriesVisible && (
                <ul>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    categories.map((category) => (
                      <li key={category.categoryId}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={category.categoryId}
                            onChange={() =>
                              handleCategoryChange(category.categoryTitle)
                            }
                            checked={tempSelectedCategories === category.categoryTitle}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={category.categoryId}
                          >
                            {category.categoryTitle}
                          </label>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              )}
            </div>

            <div className="filter-section">
              <div className="d-flex justify-content-between">
                <h4>Brands</h4>
                <div onClick={toggleBrands} style={{ cursor: "pointer" }}>
                  {brandsVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </div>
              </div>
              {brandsVisible && (
                <ul>
                  {loading ? (
                    <p>Loading...</p>
                  ) : filteredBrands.length > 0 ? (
                    filteredBrands.map((brand) => (
                      <li key={brand.brandId}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={brand.brandId}
                            onChange={() => handleBrandChange(brand.brandName)}
                            checked={tempSelectedBrands.includes(brand.brandName)}
                          />
                          <label className="form-check-label" htmlFor={brand.brandId}>
                            {brand.brandName}
                          </label>
                        </div>
                      </li>
                    ))
                  ) : !loading && filteredBrands.length === 0 ? (
                    <li>No brands available..!</li>
                  ) : null}
                </ul>
              )}
            </div>
            <div className="filter-section">
              <div className="d-flex justify-content-between">
                <h4>Rating</h4>
                <div onClick={toggleRatings} style={{ cursor: "pointer" }}>
                  {ratingsVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </div>
              </div>
              {ratingsVisible && (
                <ul>
                  {[5, 4, 3, 2, 1].map((rating) => (
                    <li key={rating}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`rating${rating}`}
                          onChange={() => handleRatingChange(rating)}
                          checked={tempMinRatings.includes(rating)}
                        />
                      </div>
                      <Rate disabled defaultValue={rating} />
                      {rating < 5 && " & UP"}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <button
              className="faq-get-in-touch-button"
              onClick={handleApplyFilters}
            >
              Apply Filter
            </button>
          </div>
        </div>

        <div className="col-lg-9">

          <Spin style={{ height: "200px" }} size="large" spinning={loading}>

            <div className="d-flex search-buttons-container gap-4 search-media-container">

              {/* <SearchOutlined style={{ fontSize: "22px", color: "#1d3a57" }} /> */}
              {/* <input
                type="text"
                placeholder={`Showing 1-${totalItems} of ${allProductsData?.length} results`}
                style={{ border: "none" }}
              /> */}
              <p>{`Showing 1-${totalItems} of ${allProductsData?.length} results`}</p>
              <svg
                className="pt-2"
                width="24"
                height="24"
                viewBox="0 0 28 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_348_1360)">
                  <path
                    d="M24.5757 15.9202H18.2689C16.4702 15.9202 15.0068 17.3836 15.0068 19.1824V25.4891C15.0068 27.2879 16.4702 28.7513 18.2689 28.7513H24.5757C26.3744 28.7513 27.8378 27.2879 27.8378 25.4891V19.1824C27.8378 17.3836 26.3744 15.9202 24.5757 15.9202ZM25.6631 25.4891C25.6631 26.0887 25.1753 26.5765 24.5757 26.5765H18.2689C17.6693 26.5765 17.1815 26.0887 17.1815 25.4891V19.1824C17.1815 18.5828 17.6693 18.095 18.2689 18.095H24.5757C25.1753 18.095 25.6631 18.5828 25.6631 19.1824V25.4891ZM9.56989 15.9202H3.26311C1.46437 15.9202 0.000976562 17.3836 0.000976562 19.1824V25.4891C0.000976562 27.2879 1.46437 28.7513 3.26311 28.7513H9.56989C11.3686 28.7513 12.832 27.2879 12.832 25.4891V19.1824C12.832 17.3836 11.3686 15.9202 9.56989 15.9202ZM10.6573 25.4891C10.6573 26.0887 10.1695 26.5765 9.56989 26.5765H3.26311C2.66353 26.5765 2.17573 26.0887 2.17573 25.4891V19.1824C2.17573 18.5828 2.66353 18.095 3.26311 18.095H9.56989C10.1695 18.095 10.6573 18.5828 10.6573 19.1824V25.4891ZM9.56989 0.914429H3.26311C1.46437 0.914429 0.000976562 2.37782 0.000976562 4.17656V10.4833C0.000976562 12.2821 1.46437 13.7455 3.26311 13.7455H9.56989C11.3686 13.7455 12.832 12.2821 12.832 10.4833V4.17656C12.832 2.37782 11.3686 0.914429 9.56989 0.914429ZM10.6573 10.4833C10.6573 11.0829 10.1695 11.5707 9.56989 11.5707H3.26311C2.66353 11.5707 2.17573 11.0829 2.17573 10.4833V4.17656C2.17573 3.57698 2.66353 3.08918 3.26311 3.08918H9.56989C10.1695 3.08918 10.6573 3.57698 10.6573 4.17656V10.4833ZM24.5757 0.914429H18.2689C16.4702 0.914429 15.0068 2.37782 15.0068 4.17656V10.4833C15.0068 12.2821 16.4702 13.7455 18.2689 13.7455H24.5757C26.3744 13.7455 27.8378 12.2821 27.8378 10.4833V4.17656C27.8378 2.37782 26.3744 0.914429 24.5757 0.914429ZM25.6631 10.4833C25.6631 11.0829 25.1753 11.5707 24.5757 11.5707H18.2689C17.6693 11.5707 17.1815 11.0829 17.1815 10.4833V4.17656C17.1815 3.57698 17.6693 3.08918 18.2689 3.08918H24.5757C25.1753 3.08918 25.6631 3.57698 25.6631 4.17656V10.4833Z"
                    fill="#1B3A57"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_348_1360">
                    <rect
                      width="27.8368"
                      height="27.8368"
                      fill="white"
                      transform="translate(0.00390625 0.918396)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="products-container gap-4">
              {!loading && displayedProducts.length === 0 ? (
                <div className="no-products-found">
                  <h2>No products found</h2>
                  <p>
                    Try adjusting your search or filters to find what you're
                    looking for.
                  </p>
                </div>
              ) : (
                <>
                  <div className="products-card-container">
                    {paginatedProducts.map((card, index) => (
                      <div key={index} className="col-md-4">
                        {renderCard(card, index)}
                      </div>
                    ))}
                  </div>

                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalItems}
                    onChange={handlePageChange}
                    className="pagination-bar"
                  />
                </>
              )}
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default FilteredProducts;