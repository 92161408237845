import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  userData: null,
  isTokenExpiredPopupVisible: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.userData = action.payload;
    },
    register: (state, action) => {
      state.isLoggedIn = true;
      state.userData = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.userData = null;
    },
    setAuthState: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.userData = { ...state.userData, ...action.payload.userData };
    },
    updateUserDetails: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
    setTokenExpiredPopupVisible: (state, action) => {
      state.isTokenExpiredPopupVisible = action.payload;
    },
  },
});

export const { login, logout, setAuthState, register, updateUserDetails,setTokenExpiredPopupVisible } = authSlice.actions;
export default authSlice.reducer;
