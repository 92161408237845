import React from 'react'
import HomeBanner from './HomeBanner'
import { OurProducts } from './OurProducts'
import { Awards } from './Awards'
import Sustainability from './Sustainability'
import Clients from './Clients'
import Testimonials from './Testimonials'
import Product from './Product'
import 'bootstrap/dist/css/bootstrap.min.css';
import SteelProduct from './SteelProduct';
import FaqGetInTouch from '../FAQ/FaqGetInTouch'
import GetApp from './GetApp'

const Home = () => {
  return (
    <div>
      <HomeBanner/>
      <OurProducts/>
      <Product />
      {/* <SteelProduct/> */}
      <Sustainability />
      <Clients /> 
      <Testimonials /> 
      <FaqGetInTouch />
      <GetApp />
    </div>
  )
}
export default Home
