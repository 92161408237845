import React, { useState,useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import "./SignIn.css";
import formImg from "../../Assets/SKImages/form-img.png";
import Instance from "../../AxiosConfig";
import { Button, Modal, Form, Input } from "antd";
import ForgotPassword from "./ForgotPassword";
import { useDispatch } from "react-redux";
import { login, logout, setAuthState } from "../../states/reducers/AuthSlice";
import { addItem,addItems } from '../../states/reducers/AddToCartSlice';
import { addWishlists } from '../../states/reducers/wishlistSlice';
const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validateEmail = (email) => {
    const trimmedEmail = email.trim();
    if (!trimmedEmail) {
      return "Email is required";
    } else if (/^\d/.test(trimmedEmail)) {
      return "Email should not start with a number";
    } else if (/[A-Z]/.test(trimmedEmail)) {  
      return "Email should not contain uppercase letters";
    } else if (/\s/.test(trimmedEmail)) {  
      return "Email should not contain spaces";
    } else if (!/@/.test(trimmedEmail) || !/\./.test(trimmedEmail)) {
      return "Email must contain '@' and a domain (e.g., '.com')";
    } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(trimmedEmail)) {
      return "Email is invalid";
    } else {
      return "";
    }
  };

  const validateForm = () => {
    let valid = true;
    let errors = { email: "", password: "" };

    const emailError = validateEmail(email);
    if (emailError) {
      errors.email = emailError;
      valid = false;
    }

    const trimmedPassword = password.trim(); 
    if (!trimmedPassword) {
      errors.password = "Password is required";
      valid = false;
    } else if (trimmedPassword.length < 6) {
      errors.password = "Password must be at least 6 characters";
      valid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(trimmedPassword)) {
      errors.password = "Password must contain a special character";
      valid = false;
    } else if (!/[A-Z]/.test(trimmedPassword)) {
      errors.password = "Password must contain at least one uppercase letter";
      valid = false
    }
    else if (!/\d/.test(trimmedPassword)) {
      errors.password = "Password must contain a number";
      valid = false;
    } else if (/\s/.test(trimmedPassword)) {  
      errors.password = "Password should not contain spaces";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    const emailError = validateEmail(value);
    setEmail(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: emailError,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let sessionId = sessionStorage.getItem('sessionId');
    console.log("asd", sessionId)
    if (validateForm()) {
      try {
        const response = await Instance.post(`/api/v1/auth/login${sessionId ? `?sessionId=${sessionId}` : ''}`, {
          email,
          password,
        });
        console.log("Form submitted", response.data);
        const token = response.data.jwtToken;
        const userData = response.data.user;
        localStorage.setItem("authToken", token);
        sessionStorage.removeItem("sessionId");
        dispatch(login(userData));
        toast.success("Login successfully!");

        setTimeout(() => {
          navigate("/");
        }, 2000);
      } catch (error) {
        console.error("Error during form submission:", error);

        if (error.response) {
          console.error("Error response:", error.response);
          let errorMessage = error.response.data ? error.response.data : "Login failed!";
          if (error.response.data && error.response.data.message) {
            const serverMessage = error.response.data.message;
            if (serverMessage.includes(":")) {
              errorMessage = serverMessage.split(":")[0];
            } else {
              errorMessage = serverMessage;
            }
          }
          toast.error(errorMessage);
        } else {
          toast.error("An error occurred.");
        }
      }
    }
  };
 
      const fetchCartCount = async () => {
        try {
          let sessionId = sessionStorage.getItem("sessionId");
          const response = await Instance.get(
            `/api/v1/cart/?sessionId=${sessionId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          );
          if (response.status === 200) {
            dispatch(addItems(response.data.cartItems))
          }
         
        } catch (error) {
          console.error("Error fetching cart count:", error);
           }
      };
    
      useEffect(() => {
        fetchCartCount(dispatch);
      },[dispatch]);
      const fetchWishlistData = async () => {
        try {
          let sessionId = sessionStorage.getItem('sessionId');
          const url = `/api/v1/wishlist/?sessionId=${sessionId}`;
          const response = await Instance.get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          });
          if (response.status === 200) {
            dispatch(addWishlists(response.data));
            console.log("wishlistData",response.data)
          }
        } catch (error) {
          console.error(error?.response?.data?.message || "An error occurred");
        }
      };
      useEffect(() => {
        fetchWishlistData(dispatch); 
      }, [dispatch]);

  const handleRegisterClick = () => {
    navigate("/register");
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div className="sign-page">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "100px" }}
        className="signin-success-message"
      />
      <div className="form-container">
        <div className="col-lg-9 left-section">
          <h1>Builderneed</h1>
          <img
            src={formImg}
            alt="Construction Materials"
            className="image-container"
          />
        </div>
        <div className="col-lg-3 right-section">
          <h2>Welcome Back, Please login to your account</h2>
          <form className="login-form" onSubmit={handleSubmit}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email address"
              value={email}
              onChange={handleEmailChange}
            />
            {errors.email && <p className="error">{errors.email}</p>}

            <label htmlFor="password">Password</label>
            <div className="password-container">

              <Form.Item
                name="password"
                id="password"
                className="signin-password-field"
                // placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onContextMenu={handleContextMenu}
                style={{ width: "100%" }}

              >
                <Input.Password className="signin-password-field"  placeholder="Enter your password"/>
              </Form.Item>
            </div>

            {errors.password && <p className="error">{errors.password}</p>}


            <div className="form-options mt-2">
              <label className="signin-remenber-label">
                <input type="checkbox" name="remember" />
                Remember me
              </label>

              <button onClick={showModal} type="button" className="forgot-password">
                Forgot Password
              </button>

            </div>

            <div className="d-flex flex-column button-container gap-3">

              {/* <button
                type="button"
                className="register-btn"
                onClick={handleRegisterClick}
                id="register-btn"
              >
                Register
              </button> */}
              <button type="submit" className="login-btn w-100" id="login-button">
                Login
              </button>
              <p className="text-allign-left">New User? <span>&nbsp;<a href="/register">Register here</a></span></p>
            </div>
          </form>
        </div>
      </div>
      <ForgotPassword
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        onClose={() => setIsModalOpen(false)}

      />
    </div>
  );
};

export default SignIn;
