import React, { useState } from 'react';
import "./ContactUs.css";
import { Button, message, Spin } from 'antd';
import Instance from '../AxiosConfig';
import { ArrowRightOutlined } from '@ant-design/icons';

const ContactForm = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        services: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        services: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value.trim();

        if (name === "email") {
            const emailValue = trimmedValue.toLowerCase();
            let emailError = "";

            if (!emailValue) {
                emailError = "Email is required";
            } else if (/^\d/.test(emailValue)) {
                emailError = "Email should not start with a number";
            } else if (/[A-Z]/.test(emailValue)) {
                emailError = "Email should not contain uppercase letters";
            } else if (!/@/.test(emailValue) || !/\./.test(emailValue) || !/.com$/.test(emailValue)) {
                emailError = "Email address is invalid.";
            } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(emailValue)) {
                emailError = "Email address is invalid";
            }

            setErrors((prevErrors) => ({
                ...prevErrors,
                email: emailError,
            }));
            setFormData({ ...formData, [name]: emailValue });

        } else if (name === "phone") {
            const numericValue = trimmedValue.replace(/[^0-9]/g, "");
            let mobileError = "";

            if (numericValue.length > 10) {
                return;
            }

            if (!numericValue) {
                mobileError = "Mobile Number is required";
            } else if (/^0+$/.test(numericValue)) {
                mobileError = "Mobile Number cannot be all zeros";
            } else if (!/^[4-9]\d{9}$/.test(numericValue)) {
                mobileError = "Mobile Number must start with a digit between 4 and 9 and be exactly 10 digits long";
            }

            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: mobileError,
            }));
            setFormData({ ...formData, [name]: numericValue });

        } else if (name === "name") {
            // Filter out numbers and any non-letter characters
            const validNameValue = trimmedValue.replace(/[^a-zA-Z\s]/g, "");
            let nameError = "";

            // Calculate the number of letters (excluding spaces)
            const letterCount = validNameValue.replace(/\s+/g, '').length;

            if (letterCount < 3 || letterCount > 15) {
                nameError = "Name must be between 3 and 15 Characters";
            } else if (!/^[a-zA-Z\s]*$/.test(validNameValue)) {
                nameError = "Name can only contain letters and spaces";
            }

            setErrors((prevErrors) => ({
                ...prevErrors,
                name: nameError,
            }));
            setFormData({ ...formData, [name]: validNameValue });

        } else {
            setFormData({ ...formData, [name]: value });
        }

        if (name === "services" || name === "subject" || name === "message") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: ''
            }));
        }
    };

    const validate = () => {
        let valid = true;
        let errors = {};

        // Validate name
        if (!formData.name) {
            errors.name = "Name is required";
            valid = false;
        } else if (!/^[a-zA-Z\s]*$/.test(formData.name)) {
            errors.name = "Name can only contain letters and spaces";
            valid = false;
        } else {
            const letterCount = formData.name.replace(/\s+/g, '').length;
            if (letterCount < 3 || letterCount > 15) {
                errors.name = "Name must be between 3 and 15 letters";
                valid = false;
            }
        }

        // Validate email
        if (!formData.email) {
            errors.email = "Email is required";
            valid = false;
        } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(formData.email)) {
            errors.email = "Email address does not appear to be valid";
            valid = false;
        }

        // Validate phone
        if (!formData.phone) {
            errors.phone = "Phone number is required";
            valid = false;
        } else if (!/^[4-9]\d{9}$/.test(formData.phone)) {
            errors.phone = "Phone number must start with a digit between 4 and 9 and be exactly 10 digits long";
            valid = false;
        }

        // Validate services
        if (!formData.services) {
            errors.services = "Services field is required";
            valid = false;
        }

        // Validate subject
        if (!formData.subject) {
            errors.subject = "Subject field is required";
            valid = false;
        }

        // Validate message
        if (!formData.message) {
            errors.message = "Message is required";
            valid = false;
        }

        setErrors(errors);
        return valid;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();  
        if (validate()) {
            setLoading(true);
            try {
                const data = {
                    name: formData.name,
                    email: formData.email,
                    phoneNumber: formData.phone,
                    services: formData.services,
                    subject: formData.subject,
                    message: formData.message
                };

                const response = await Instance.post("/api/v1/contact-us/create", data);

                if (response.status === 200 || response.status === 201) {
                    message.success("Request Sent Successfully");
                    console.log("Form data submitted successfully");
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        services: '',
                        subject: '',
                        message: ''
                    });
                } else {
                    console.error("Failed to submit form data:", response);
                }
            } catch (error) {
                console.error("An error occurred while submitting the form:", error);
                setLoading(false);
            }finally{
                setLoading(false);
            }
        }
        
    };



    return (
        <div className="contact-main">

            <div className="container contact-container">
                <Spin spinning={loading} size="large">

                    <form onSubmit={handleSubmit}>
                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="name" className="d-flex text-align-left">Name</label>
                                <input
                                    type="text"
                                    className="form-control contactForm-input"
                                    placeholder="Enter name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <div className="error">{errors.name}</div>}
                            </div>
                            <div className="col">
                                <label htmlFor="email" className="d-flex text-align-left">E-mail</label>
                                <input
                                    type="text"
                                    className="form-control contactForm-input"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <div className="error">{errors.email}</div>}
                            </div>
                        </div>
                        <div className="col mb-3">
                            <label htmlFor="phone" className="d-flex text-align-left">Phone Number</label>
                            <input
                                type="text"
                                className="form-control contactForm-input"
                                placeholder="Enter phone number"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                            {errors.phone && <div className="error">{errors.phone}</div>}
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label htmlFor="services" className="d-flex text-align-left">Services</label>
                                <input
                                    type="text"
                                    className="form-control contactForm-input"
                                    placeholder="What are you looking for"
                                    name="services"
                                    value={formData.services}
                                    onChange={handleChange}
                                />
                                {errors.services && <div className="error">{errors.services}</div>}
                            </div>
                            <div className="col">
                                <label htmlFor="subject" className="d-flex text-align-left">Subject</label>
                                <input
                                    type="text"
                                    className="form-control contactForm-input"
                                    placeholder="Subject"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                />
                                {errors.subject && <div className="error">{errors.subject}</div>}
                            </div>
                        </div>
                        <div className="col mb-3">
                            <label htmlFor="message" className="form-label d-flex text-align-left">Message</label>
                            <textarea
                                className="form-control contactForm-input"
                                id="exampleFormControlTextarea1"
                                placeholder="Tell us about your project"
                                rows="3"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                            />
                            {errors.message && <div className="error">{errors.message}</div>}
                        </div>

                        <div className="d-flex justify-content-center">
                            <Button type="primary" htmlType="submit" className="contact-button contact-button d-flex justify-content-center align-items-center">Contact Us
                            <ArrowRightOutlined />
                            </Button>
                        </div>
                    </form>
                </Spin>
            </div>
        </div>
    );
};

export default ContactForm;
