import React from 'react'
import RecentViewedCarasoul from "../../Components/Home/RecentlyViewedCarasoul"
import StepProcess from './StepProcess';
import SteelCarousel from '../Home/SteelCarousel';


 const ShoppingCart = () => {
  return (
    <div>
      <StepProcess />
      <RecentViewedCarasoul />
      <SteelCarousel/>
    </div>
  )
}
export default ShoppingCart;