import React, { useState } from "react";
import { Modal, Button } from "antd";
import logo from "../../Assets/SKImages/white-bg-logo.png";
import Instance from "../../AxiosConfig";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import "./SignIn.css";

const NewPasswordModal = ({ isVisible, onClose, email }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const[successResponse,setSuccessResponse]=useState("");
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validatePassword = (password) => {
    // Password validation rules
    const regex = /^(?=.*[!@#$%^&*()])(?=.*\d).{6,}$/;
    return regex.test(password);
  };

  const handlePasswordBlur = () => {
    if (!password) {
      setPasswordError("Password is required.");
    } else if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 6 characters long and contain at least one special character and one number."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordBlur = () => {
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required.");
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Password confirmation does  not match password!");
    } else {
      setConfirmPasswordError("");
    }
  };


  const handleUpdatePassword = async () => {
    handlePasswordBlur();
    handleConfirmPasswordBlur();

    if (passwordError || confirmPasswordError || !password || !confirmPassword) {
      return;
    }

    try {
      const response = await Instance.post(`/api/v1/forgotPassword/changePassword/${email}`, {
        password: password,
        confirmPassword: confirmPassword,
        email: email,
      });
      console.log("Password updated", response);
      setSuccessResponse(response.data)
      onClose();
      setSuccessModalVisible(true);
    } catch (error) {
      console.error("Error during password update:", error);
    }
  };

  const handleSuccessModalClose = () => {
    setSuccessModalVisible(false);
    onClose();
  };

  return (
    <>
      <Modal visible={isVisible} onCancel={onClose} footer={null}>
        <div className="d-flex justify-content-center flex-column forgot-password-div">
          <div className="d-flex justify-content-center">
            <img src={logo} alt=".." />
          </div>

          <h2>New Password</h2>
          <p>
            Set the new password for your account so you can login and access
            all features.
          </p>
          <form className="login-form">
            <label htmlFor="password">Enter new Password</label>
            <div className="password-container">
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={handlePasswordBlur}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              <button
                type="button"
                className="password-toggle"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </button>
            </div>
            {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
            <label htmlFor="confirm-password">Confirm Password</label>
            <div className="password-container">
              <input
                type={passwordVisible ? "text" : "password"}
                id="confirm-password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={handleConfirmPasswordBlur}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                
              />
              <button
                type="button"
                className="password-toggle"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </button>
            </div>
            {confirmPasswordError && (
              <p style={{ color: "red" }}>{confirmPasswordError}</p>
            )}
          </form>
          <Button
            key="submit"
            type="primary"
            className="continue-button"
            onClick={handleUpdatePassword}
          >
            Update Password
          </Button>
        </div>
      </Modal>
      <Modal
        visible={successModalVisible}
        onCancel={handleSuccessModalClose}
        footer={null}
      >
        <div className="d-flex justify-content-center flex-column forgot-password-div">
          <CheckCircleOutlined
            style={{ color: "#1b3a57", fontSize: "78px" }}
            className="d-flex justify-content-center"
          />
          <h2 className="text-center">Success</h2>
          <p className="text-center">
            {successResponse}
          </p>
          <Button
            key="submit"
            type="primary"
            className="continue-button"
            onClick={handleSuccessModalClose}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default NewPasswordModal;
