import React, { useEffect, useState } from "react";
import { Modal, Button, message } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import shoppingCartcss from "../Components/Cart/ShoppingCart.css";
import Instance from "../AxiosConfig";
import { useLocation, useNavigate } from "react-router-dom";

const FailurePopUpForm = () => {
  const [modalVisible, setModalVisible] = useState(true);
  const navigate = useNavigate()
  const location = useLocation();
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);


  const handleSuccessModalClose = () => {
    setModalVisible(false);
  };
  const handleShopping = () => {
    navigate('/shop')
  }
  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };
  const orderId = getQueryParams().get("orderId")
  console.log("orderIdis", orderId)
  const fetchOrderDetails = async () => {
    setLoading(true);
    try {
      const response = await Instance.get(`/api/v1/orders/${orderId}`);
      if (response.status === 200) {
        setOrderData(response.data);
        console.log("Fetched order details:", response.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOrderDetails();
  }, [orderId]);

  return (
    <>
      <div style={{ height: "400px" }}></div>
      <Modal
        visible={modalVisible}
        footer={null}
        onCancel={handleSuccessModalClose}
       
        centered
      >
        <div className="d-flex flex-column align-items-center">
          <ExclamationCircleFilled
            style={{ color: "#ce1b28", fontSize: "50px" }}
            className="d-flex justify-content-center mt-2"
          />
          <h6 className="text-center mt-3">Payment failure</h6>
          <h5 className="text-center mt-2">₹{(orderData.paymentDetails?.amountReceived?? 0).toFixed(2)}</h5>
          <div
            className="order-summary-payment text-center mt-3 justify-content-center"
            style={{ width: "100%" }}
          >
            <div className="container-fluid">
            <div className="total-table-failure">
                <div className="d-flex justify-content-between header-order-summary">
                  <b><p>Order Summary</p></b>
                </div>
                <div className="d-flex justify-content-between pt-2">
                  <p>Total Items</p>
                  <b><p>{orderData.totalItem?.toFixed(2)}</p></b>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Subtotal</p>
                  <b><p>₹{(orderData.totalPrice?? 0).toFixed(2)}</p></b>
                </div>
                <div className="d-flex justify-content-between">
                  <p>Discount</p>
                  <b><p>-₹{(orderData.discount?? 0).toFixed(2)}</p></b>
                </div>
                {orderData.couponApplied &&
                  <div className="d-flex justify-content-between">
                    <p >Coupon Savings</p>
                    <b><p>-₹{(orderData.couponDiscountedPrice?? 0).toFixed(2)}</p></b>
                  </div>}
                <div className="d-flex justify-content-between">
                  <p>Delivery Fee</p>
                  <b><p>₹{(orderData.deliveryFee?? 0).toFixed(2)}</p></b>
                </div>
                <div className="d-flex justify-content-between successpopup-total">
                  <b>Total</b>
                  <b>₹{(orderData.totalPriceIncludeDeliveryFee?? 0).toFixed(2)}</b>
                </div>
                {/* <div className="d-flex justify-content-between">
                  <p className="mt-2" >Amount Received</p>
                  <b className="mt-2">-₹{(orderData.paymentDetails?.amountReceived ?? 0).toFixed(2)}</b>
                </div>
                <div className="d-flex justify-content-between">
                  <b >Balance Amount</b>
                  <b>₹{((orderData.totalPriceIncludeDeliveryFee - orderData.paymentDetails?.amountReceived)??0).toFixed(2)}</b>
                </div> */}
              </div>
            </div>
          </div>
          <Button
              type="link"
              onClick={handleShopping}
              className="download-button"
            >
              Continue Shopping
            </Button>
        </div>
      </Modal>
    </>
  );
};

export default FailurePopUpForm;
