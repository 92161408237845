import React from 'react'
import ContactForm from './ContactForm'
import ContactInfo from './ContactInfo'
import ContactBanner from './ContactBanner'

const ContactUs = () => {
  return (
    <>
    <ContactBanner/>
    <ContactForm />
    <ContactInfo />
    </>
  )
}

export default ContactUs