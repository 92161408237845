import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Footer from "./Layout/Footer/Footer";
import MainHeader from "./Layout/Header/header";
import Home from "./Components/Home/Home";
import Register from "./Components/Authentication/Register";
import SignIn from "./Components/Authentication/SignIn";
import Shop from "./Components/Shop/Shop";
import Faq from "./Page/Faq";
import SuccessPopUpForm from "./Page/SuccessPopUpForm";
import FailurePopUpForm from "./Page/FailurePopUpForm";
import { AboutPage } from "./Page/AboutPage";
import ContactUsPage from "./Page/ContactUsPage";
import ProfileAccount from "./Page/ProfileAccount";
import ProductDetails from "./Components/Shop/ProductDetails";
import MyOrders from "./Components/Profile/My orders/MyOrders";
import ShoppingCart from "./Components/Cart/ShoppingCart";
import { Wishlist } from "./Components/Profile/WishList/Wishlist";
import { MyDetails } from "./Components/Profile/Details/MyDetails";
import { AddAdress } from "./Components/Profile/Details/AddAdress";
import OrderDetails from "./Components/Profile/My orders/OrderDetails";
import { SignOut } from "./Components/Authentication/SignOut";
import ScrollToTop from "./Components/ScrollToTop/scrollTop";
import { WishlistLogout } from "./Page/Wishlist";
import FilteredProducts from "./Components/Home/filteredProducts";
import { EditAddress } from "./Components/Profile/Details/EditAddress";
import StepProcess from "./Components/Cart/StepProcess";
import Protect from "./Protect";
import PageNotFound from "./Page/PageNotFound";
import PrivacyPolicy from "./Page/PrivacyPolicy";
import CancellationAndRefund from "./Page/returns";
import ShippingInfo from "./Page/ShippingInfo";
import ChangePassword from "./Components/Profile/ChangePassword/ChangePassword";
import TermsAndConditions from "./Page/TermsAndConditions";
import MyOrdersInvoice from "./Components/Home/MyOrdersInvoice";
const AppRoutes = () => {
  
  return (
    <>
      <Router>
        <MainHeader />
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product-details/:productId" element={<ProductDetails />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/register" element={<Register />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/order-details" element={<OrderDetails />} />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/shopping-cart" element={<ShoppingCart />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/cart" element={<StepProcess />} />
          <Route path="/address" element={<Protect Component= {StepProcess} />} />
          <Route path="/payment-page/:orderId" element={<Protect Component= {StepProcess} />} />
          <Route path="/wishlist" element={<WishlistLogout />} />
          <Route path="/success" element={<SuccessPopUpForm />} />
          <Route path="/failure" element={<FailurePopUpForm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/cancellation-and-refund" element={<CancellationAndRefund/>} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
          <Route path="/shipping-info" element={<ShippingInfo/>} />
          <Route path="/filtered-products" element={<FilteredProducts />} />
          <Route path="/downloadInvoice/:orderId" element={<MyOrdersInvoice />} />
          <Route path="/profile/" element={<Protect Component= {ProfileAccount} />}>
            <Route path="/profile/my-orders" element={<MyOrders />} />
            <Route path="/profile/my-orders/order-details" element={<OrderDetails />} />
            <Route path="/profile/wishlist" element={<Wishlist />} />
            <Route path="/profile/details" element={<MyDetails />} />
            <Route path="/profile/change-password" element={<ChangePassword />} />
            <Route path="/profile/details/add-address" element={<AddAdress />} />
            <Route path="/profile/details/edit-address" element={<EditAddress />} />
            <Route path="/profile/sign-out" element={<SignOut />} />
            <Route index element={<Navigate to="details" replace />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};
export default AppRoutes;
