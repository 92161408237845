import React from "react";
import "./about.css";
import { useNavigate } from "react-router-dom";

const AboutBanner = () => {
const navigate = useNavigate();

  const handleContact = () => {
    navigate("/contact-us");
  };
  return (
    <div className="container-fluid" style={{ overflow: "hidden" }}>
      <div className="row about-banner-row">
        <div className="col-lg-6 banner-content-contact  ms-md-5">
          <h4
            style={{ letterSpacing: "1px", color: "#fff", fontWeight: "700" }}
          >
            Building the Future with Quality and Reliability
          </h4>
          <h2
            className="about-banner-h2"
            style={{
              fontWeight: "700",
              fontStyle: "italic",
              fontSize: "48px",
              color: "#fff",
            }}
          >
           Quality Materails for Exceptional Builds
          </h2>

          <p style={{ fontSize: "22px", color: "#fff" }}>
          Your Trusted Partner in Quality Building Materials
          </p>
          <button className="explore-button-shop"onClick={handleContact} >Contact Us</button>
        </div>
      </div>
    </div>
  );
};
export default AboutBanner;
