import React, {useEffect, useState} from 'react'
import faqImg from "../../Assets/SKImages/faq-img.png"
import "./faq.css"
import { useNavigate } from 'react-router-dom'

const FaqGetInTouch = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1600);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const RedirectPage = () => {
    navigate("/contact-us");
  };

  return (
      <div className='container get-in-container mb-4'>
      <div className="col-lg-6 ">
        <div className="d-flex flex-column pe-4 title-get">
        <h2 className=''>Still have questions?</h2>
        <p>Can't find the answer you're looking for?</p>
        {/* <p>Please chat to our friendly team.</p> */}
        <button className="faq-get-in-touch-button" onClick={RedirectPage}>Get in touch</button>
        </div>
        </div>
      {/* <div className="col-lg-6">
        <img className="get-in-img" src={ faqImg } alt=''/>
      </div> */}
      </div>
  )
}

export default FaqGetInTouch
