import React from 'react'
import ContactUs from '../ContactUs/ContactUs'

export const ContactUsPage = () => {
  return (
    <div>
        <ContactUs/>
    </div>
  )
}

export default ContactUsPage
