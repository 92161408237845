import { createSlice } from '@reduxjs/toolkit';

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState: {
    wishlistsData: [],
  },
  reducers: {
    addWishlist: (state, action) => {
      state.wishlistsData.push(action.payload);
    },
    addWishlists: (state, action) => {
      state.wishlistsData = action.payload;
    },
    removeWishlist: (state, action) => {
      state.wishlistsData = state.wishlistsData.filter(
        wishlist => wishlist.productId !== action.payload
      );
    },
    clearWishlist: (state) => {
      state.wishlistsData = [];
    },

  },
});

export const { addWishlist, removeWishlist, addWishlists ,clearWishlist} = wishlistSlice.actions;
export default wishlistSlice.reducer;
