import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addressData: null,
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setAddressData: (state, action) => {
      state.addressData = action.payload;
    },
    clearAddressData: (state) => {
      state.addressData = null;
    },
  },
});

export const { setAddressData, clearAddressData } = addressSlice.actions;

export default addressSlice.reducer;
