import React, { useState, useEffect } from 'react';
import Instance from "../../AxiosConfig"
import './faq.css';

const FaqQuestions = () => {
  const [faqs, setFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1600);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchFaqsData = async () => {
    setLoading(true);
    try {
      const response = await Instance.get("/api/v1/faqs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminAuthToken")}`,
          "Content-Type": "application/json",
        }
      });
      if (response.status === 200) {
        console.log('Fetched FAQ data:', response.data);
        setFaqs(response.data);
      }
    } catch (error) {
      console.error("Error fetching faqs data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFaqsData();
  }, []);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
      <div className= 'container faq-section mt-4 mb-4'>
      <h2 className="faq-heading">Frequently Asked Questions</h2>
      <p className="faq-intro">Frequently asked questions (FAQs) about cement often cover a range of topics related to its use, types, production, and environmental impact. Here are some common questions and answers:</p>
      <div className="qn-container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          faqs.map((faq, index) => (
            <div key={index} className="faq-item mt-4">
              <div className="faq-question-box" onClick={() => handleToggle(index)}>
                <h3 className="faq-question">{faq.title}</h3>
                <span className="faq-toggle-icon">{activeIndex === index ? '-' : '+'}</span>  
              </div>
              {activeIndex === index && <div className="faq-answer-box mt-4"><p className="faq-answer">{faq.description}</p></div>}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default FaqQuestions;
