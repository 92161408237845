import { createSlice } from '@reduxjs/toolkit';

export const CouponSlice = createSlice({
  name: 'coupon1',
  initialState: {
    couponData: [],
  },
  reducers: {
    addCoupon: (state, action) => {
      state.couponData.push(action.payload);
    },
    removeCoupon: (state, action) => {
      state.couponData = state.couponData.filter(
        coupon => coupon.productId !== action.payload
      );
    },
  },
});

export const { addCoupon, removeCoupon } = CouponSlice.actions;
export default CouponSlice.reducer;
