import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Steps, Rate, Popover, Input, message, Modal } from "antd";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import "./MyOrders.css";
import icon1 from "../../../Assets/icons/product-status.png";
import INRrupee from "../../Home/INRrupee"
import "./Orderspage"
import { ArrowLeftOutlined } from "@ant-design/icons";
import star from "../../../Assets/icons/star.png";
import Instance from "../../../AxiosConfig";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import InvoiceLogo1 from '../../../Assets/SKImages/pdf-header-logo.png'



const { Step } = Steps;

const OrderDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { order } = location.state || {};
  const [expandedItems, setExpandedItems] = useState({});
  const [value, setValue] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState({ name: "", review: "" });
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [succesModalVisible, setSuccesModalVisible] = useState(false);


  const groupOrderItems = (orderItems) => {
    const groupedItems = {};

    (orderItems || []).forEach((item) => {
      const key = item.productId;

      if (groupedItems[key]) {
        groupedItems[key].quantity += item.quantity;
        groupedItems[key].total += item.price;
        groupedItems[key].totalDiscountedPrice += item.discountedPrice;
        if (groupedItems[key].steelProduct && groupedItems[key].total > 0) {
          const discount = ((groupedItems[key].total - groupedItems[key].totalDiscountedPrice) / groupedItems[key].total) * 100;
          groupedItems[key].discountPercentage = discount?.toFixed(2);
        }
      } else {
        groupedItems[key] = {
          ...item,
          quantity: item.quantity,
          total: item.price,
          totalDiscountedPrice: item.discountedPrice,
          discountPercentage: item.discountPercentage,
        };
        if (item.steelProduct && groupedItems[key].totalPrice > 0) {
          const discount = ((groupedItems[key].totalPrice - groupedItems[key].totalDiscountedPrice) / groupedItems[key].totalPrice) * 100;
          groupedItems[key].discountPercentage = discount;
        }
      }
    });

    return Object.values(groupedItems);
  };
  const handleClick = (record) => {
    console.log("RECORD",record)
    navigate(`/profile/my-orders/order-details/invoice/${record.orderId}`);
  };

  const handleExportRows = (record) => {
    const groupedOrderItems = groupOrderItems(record.orderItems);
    console.log("pdfdata:", record);
    const doc = new jsPDF();

    // General table data
    const logoImage = new Image();
    logoImage.src = InvoiceLogo1;

    logoImage.onload = function () {
      doc.setFillColor("#fff");
      doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");
      doc.addImage(logoImage, "PNG", 15, 10, 70, 20);

      doc.setFontSize(11);
      doc.setTextColor(80);

      const baseX = 95;
      const baseY = 12;
      const lineHeight = 6;
      const labelX = 136;
      const valueX = 165;

      doc.text(`Order ID:`, labelX, baseY);
      doc.text(`Name:`, labelX, baseY + lineHeight);
      doc.text(`Order date:`, labelX, baseY + 2 * lineHeight);
      doc.text(`Order Status:`, labelX, baseY + 3 * lineHeight);
      doc.text(`Payment Status:`, labelX, baseY + 4 * lineHeight);


      doc.text(`${record.orderId}`, valueX, baseY);
      doc.text(`${record.username}`, valueX, baseY + lineHeight);
      doc.text(`${record.orderDate}`, valueX, baseY + 2 * lineHeight);
      doc.text(`${(record?.orderStatus ?? '')?.toString()?.replace(/_/g, ' ')}`, valueX, baseY + 3 * lineHeight);
      doc.text(`${record.paymentDetails?.paymentStatus}`, valueX, baseY + 4 * lineHeight);


      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const borderMargin = 7;
      doc.rect(borderMargin, borderMargin, pageWidth - 2 * borderMargin, pageHeight - 2 * borderMargin);

      const tableHeaders = [
        "NAME",
        "PRICE",
        "QTY",
        "DISCOUNT%",
        // "PAYMENT STATUS",
        "TOTAL"
      ];
      const groupedOrderItems = record.orderItems.reduce((acc, item) => {
        if (!acc[item.productId]) {
          acc[item.productId] = { ...item, quantity: 0, discountedPrice: 0, totalPrice: 0, price: 0, discountPercentage: 0 };
        }
        acc[item.productId].price += item.price;
        acc[item.productId].discountPercentage += item.discountPercentage;
        acc[item.productId].quantity += item.quantity;
        acc[item.productId].discountedPrice += item.discountedPrice;

        return acc;
      }, {});

      const tableData = Object.values(groupedOrderItems).map(item => [
        item.title,
        (item.price ?? 0).toFixed(2),
        item.quantity,
        `${item.discountPercentage}%`,
        // record.paymentDetails.paymentStatus || 'N/A',
        (item.discountedPrice ?? 0).toFixed(2),
      ]);

      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        startY: 40,
        headStyles: {
          fillColor: [27, 58, 87],
          textColor: [255, 255, 255],
        },
        styles: {
          cellPadding: 4,
          minCellHeight: 8,
          lineWidth: 0.2,
          lineColor: [200, 200, 200],
        },
        columnStyles: {
          0: { cellWidth: 37 },
          3: { cellWidth: 37 },
          4: { cellWidth: 29 },// Set the width of the "PRODUCT NAME" column
        },
      });

      const processSteelTableData = (data) => {
        let previousBrand = '';
        let rowspan = 1;
        const processedData = [];

        data.forEach((row) => {
          if (row[0] === previousBrand) {
            // Clear the brand cell if it's not the first row of the same brand group
            row[0] = '';
          } else {
            previousBrand = row[0];
          }
          processedData.push(row);
        });

        return processedData;
      };

      let steelTableData = (record.orderItems || [])
        .filter(item => item.steelProduct)
        .sort((a, b) => parseFloat(a.steelSize) - parseFloat(b.steelSize))
        .map(item => [
          item.brand,
          item.title,
          item.steelSize,
          `${(item.price ?? 0).toFixed(2)}`,
          `${item.discountPercentage}%`,
          item.quantity,
          `${(item.discountedPrice ?? 0).toFixed(2)}`,
        ]);

      // Sort the table data by brand to ensure similar brands are grouped together
      steelTableData.sort((a, b) => a[0].localeCompare(b[0]));

      // Process the table data to group by brand
      steelTableData = processSteelTableData(steelTableData);

      if (steelTableData.length > 0) {
        const steelTableHeaders = [
          "BRAND",
          "PRODUCT NAME",
          "STEEL SIZE",
          "PRICE",
          "DISC %",
          "QTY",
          "DISC PRICE",
        ];

        doc.autoTable({
          head: [steelTableHeaders],
          body: steelTableData,
          startY: doc.lastAutoTable.finalY + 10,
          headStyles: {
            fillColor: [27, 58, 87],
            textColor: [255, 255, 255],
          },
          styles: {
            cellPadding: 4,
            minCellHeight: 8,
            lineWidth: 0.2,
            lineColor: [200, 200, 200],
          },
          columnStyles: {
            0: { cellWidth: 30 },
            2: { cellWidth: 20 },
            4: { cellWidth: 19 },
          },
        });
      }


      const finalY = doc.lastAutoTable.finalY;
      const labelMarginLeft = 140;
      const valueMarginLeft = 177;

      const labelMarginLeft2 = 14;
      const valueMarginLeft2 = 41;

      doc.setFontSize(12);
      doc.setTextColor(80);

      doc.text("Subtotal:", labelMarginLeft, finalY + 10);
      doc.text("Discount Amount:", labelMarginLeft, finalY + 20);
      if (record.couponApplied) {
        doc.text("Coupon Savings:", labelMarginLeft, finalY + 30);
      }
      doc.text("Shipping:", labelMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));
      doc.line(labelMarginLeft, finalY + 33 + (record.couponApplied ? 10 : 0), valueMarginLeft + 20, finalY + 33 + (record.couponApplied ? 10 : 0));
      doc.text("Total Amount:", labelMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));
      if (record.paymentType == "COD") {
        doc.text(`Amount Paid:`, labelMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));
        doc.text(`Balance Amount:`, labelMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
      }


      doc.text(`${(record.totalPrice ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 10);
      doc.text(`-${(record.discount ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 20);
      if (record.couponApplied) {
        doc.text(`-${(record.couponDiscountedPrice ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 30);
      }
      doc.text(`${(record.deliveryFee ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));
      doc.text(`${(record.totalPriceIncludeDeliveryFee ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));
      if (record.paymentType == "COD") {
        doc.text(`-${(record.paymentDetails.amountReceived ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));
        doc.text(`${((record.totalPriceIncludeDeliveryFee - record.paymentDetails.amountReceived) ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
      }


      doc.setFontSize(11);
      const verticalOffset = record.paymentType === "COD" ? 10 : 0;
      const maxWidth = 90;
      const initialY = finalY + 10;



      doc.text("Delivery To:", labelMarginLeft2, initialY);
      doc.text("Payment Type:", labelMarginLeft2, initialY + 10);
      if (record.paymentType == "COD") {
        doc.text("Payment Id:", labelMarginLeft2, initialY + 20);
      }
      doc.text("Mobile No:", labelMarginLeft2, initialY + 20 + verticalOffset);
      doc.text("Address :", labelMarginLeft2, initialY + 28 + verticalOffset);


      doc.text(`${record.shippingAddress.addressType}`, valueMarginLeft2, initialY, { maxWidth });
      doc.text(`${record.paymentType}`, valueMarginLeft2, initialY + 10, { maxWidth });
      if (record.paymentType == "COD") {
        doc.text(`${record.paymentDetails.paymentId}`, valueMarginLeft2, initialY + 20, { maxWidth });
      }
      doc.text(`+91 ${record.shippingAddress.mobile}`, valueMarginLeft2, initialY + 20 + verticalOffset, { maxWidth });
      const lineHeight1 = 1.5;
      doc.text(
        `${record.shippingAddress.streetAddress}\n${record.shippingAddress.address ? record.shippingAddress.address + '\n' : ''}${record.shippingAddress.city}, ${record.shippingAddress.state}, ${record.shippingAddress.zipCode} ${record.shippingAddress.country}`,
        valueMarginLeft2,
        initialY + 28 + verticalOffset,
        { maxWidth, lineHeightFactor: lineHeight1 }
      );


      const totalPages = doc.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        // Adjust the page number positioning
        doc.text(`Page ${i} of ${totalPages}`, pageWidth - 20, pageHeight - 1, {
          align: 'right',
        });
      }

      // const pdfBase64 = doc.output('datauristring');
      // const newWindow = window.open();
      // newWindow.document.write(`<iframe width="100%" height="100%" src="${pdfBase64}"></iframe>`);
      const fileName = `Order_${record.orderId}.pdf`;
      doc.save(fileName);

    }
  };

  if (!order) {
    navigate("/profile/my-orders");
    return null;
  }

  const customIcon = () => (
    <img
      src={icon1}
      style={{ width: "20px", height: "20px" }}
      alt="custom-icon"
    />
  );

  const handleCardClick = (productId) => {
    console.log("Navigating to product:", productId); // Debugging line
    navigate(`/product-details/${productId}`);
  };
  const handleBack = () => {
    navigate('/profile/my-orders')
  }

  const toggleExpand = (productId) => {
    setExpandedItems((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  const groupedSteelProducts = order.orderItems.reduce((acc, item) => {
    if (item.steelProduct) {
      if (!acc[item.productId]) {
        acc[item.productId] = [];
      }
      acc[item.productId].push(item);
    }
    return acc;
  }, {});
  const getStatusIndex = (status) => {
    switch (status) {
      case "PENDING":
        return 0;
      case "IN_PROGRESS":
        return 1;
      case "SHIPPED":
        return 2;
      case "DELIVERED":
        return 3;
      case "CANCELLED":
        return 1;
      default:
        return 0;
    }
  };

  const stepMessages = order.orderStatus === "CANCELLED" ? [
    "Your order has been placed.",
    <span style={{ color: 'red' }}>Your order has been cancelled.</span>,
  ] : [
    "Your order has been placed.",
    "Your order is being processed.",
    "Your order has been shipped.",
    "Your order has been delivered.",
  ];
  const stepsCount = stepMessages.length;

  const stepsStyle = {
    width: stepsCount === 2 ? '50%' : 'auto', // Adjust width based on steps count
    margin: '0 auto', // Center the steps if width is adjusted
  };
  const stepTitles = order.orderStatus === "CANCELLED" ? [
    "Order Placed",
    "Cancelled",
  ] : [
    "Order Placed",
    "In Progress",
    "Shipped",
    "Delivered",
  ];


  const currentStepIndex = getStatusIndex(order.orderStatus);


  const redirectToShopPage = () => {
    navigate("/shop");
  };

  const handleReviewModal = (productId) => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      setSelectedProductId(productId);
      setModalVisible(true);
    } else {
      navigate("/signin");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleReview = async () => {
    if (value === 0) {
      message.error("Please select a rating before submitting.");
      return;
    }
    setSuccesModalVisible(true);

    try {
      const url = `/api/v1/rating/review/create/${selectedProductId}`;
      const response = await Instance.post(
        url,
        {
          review: formData.review,
          rating: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        message.success("review submitted successfully");
        setSuccesModalVisible(true);
        setModalVisible(false);
        setFormData({ name: "", review: "" });
        setValue(0); // Reset the rating value
        // fetchReviewData();
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || "An error occurred while submitting"
      );
    }
  };

  return (
    <div className="container mt-4 p-1 order-details-container">
      <h2>My Orders</h2>
      <div className="order-card">
        <div className="d-flex flex-column  myOrders-details-div">
          <div className="d-flex justify-content-between order-header">
            <div className="order-time d-flex gap-2">
              <div className="ordersPage-div-p">
                <p> <span style={{ color: "#807D7E" }}> Order no</span>&nbsp; </p>
                <p> <span style={{ color: "#807D7E" }}>Order Date</span>&nbsp; </p>
                <p> <span style={{ color: "#807D7E" }}>Order Status</span>&nbsp;
                  &nbsp;

                </p>
                <p>
                  <span style={{ color: "#807D7E" }}>Payment Method</span>{" "}
                </p>
              </div>
              <div className="ordersPage-div-p ">
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
              </div>
              <div className="ordersPage-div-p">
                <p>#{order.orderId}</p>
                <p>{order.orderDate}</p>
                <p>{order.orderStatus}</p>
                <p>{order.paymentDetails.paymentMethod ? order.paymentDetails.paymentMethod : "NA"}</p>
              </div>

            </div>


            <div className="order-time d-flex gap-2">
              <div className="ordersPage-div-p">
                <p>
                  <span style={{ color: "#807D7E" }}>Payment Type</span>{" "}&nbsp;

                </p>


                <p>
                  <span style={{ color: "#807D7E" }}>Payment Status</span>{" "}

                </p>
                <p>
                  <span style={{ color: "#807D7E" }}>Total Amount</span>&nbsp;

                </p>
                <p>
                  <span style={{ color: "#807D7E" }}>Amount Paid</span>{" "}&nbsp;

                </p>
                {
                  !(
                    order?.paymentDetails?.paymentStatus === "COMPLETED" || order?.orderStatus === "CANCELLED" ||
                    (order?.paymentDetails?.paymentMethod === "NA" &&
                      order?.paymentType === "NA")
                  ) && (
                    <p>
                      <span style={{ color: "#807D7E" }}>Balance Amount </span>{" "}

                    </p>
                  )}
              </div>
              <div className="ordersPage-div-p ordersDetails-mobile">
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                {
                  !(
                    order?.paymentDetails?.paymentStatus === "COMPLETED" || order?.orderStatus === "CANCELLED" ||
                    (order?.paymentDetails?.paymentMethod === "NA" &&
                      order?.paymentType === "NA")
                  ) && (
                    <p>:</p>
                  )}
              </div>
              <div className="ordersPage-div-p">
                <p>{order?.paymentType ? order?.paymentType : "NA"}</p>
                <p>{order.paymentDetails.paymentStatus}</p>
                <p><INRrupee />{order.couponApplied
                  ? (order.totalAfterDiscount + order.deliveryFee).toFixed(2)
                  : (order.totalDiscountedPrice + order.deliveryFee).toFixed(2)}</p>
                <p><INRrupee />
                  {
                    order?.paymentDetails?.paymentStatus.trim() === "COMPLETED"
                      ? order?.couponApplied === true
                        ? ((order?.totalAfterDiscount || 0) + (order?.deliveryFee || 0)).toFixed(2)
                        : ((order?.totalDiscountedPrice || 0) + (order?.deliveryFee || 0)).toFixed(2)
                      : (order?.paymentDetails?.amountReceived || 0).toFixed(2)
                  }</p>
                {
                  !(
                    order?.paymentDetails?.paymentStatus === "COMPLETED" || order?.orderStatus === "CANCELLED" ||
                    (order?.paymentDetails?.paymentMethod === "NA" &&
                      order?.paymentType === "NA")
                  ) && (
                    <p>
                      <INRrupee />
                      {
                        order?.couponApplied === true
                          ? ((order?.totalAfterDiscount || 0) + (order?.deliveryFee || 0) - (order?.paymentDetails?.amountReceived || 0)).toFixed(2) // Coupon applied
                          : ((order?.totalDiscountedPrice || 0) + (order?.deliveryFee || 0) - (order?.paymentDetails?.amountReceived || 0)).toFixed(2) // Coupon not applied
                      }
                    </p>
                  )
                }
              </div>
            </div>
          </div>
          {order.orderStatus !== "PENDING" && (
            <>
              <Divider className="devider" />

              <div className="text-align-center d-flex justify-content-center mt-0 mb-0">
                <a className="text-decoration-none text-align-center" href=""
                  onClick={(e) => {
                    e.preventDefault();
                    handleExportRows(order);
                    // handleClick(order);
                  }}>Download Invoice</a>
              </div>
            </>
          )}
        </div>


        <Divider style={{ margin: '0px' }} />
        <Steps current={currentStepIndex} className="order-steps">
          {stepMessages.map((message, index) => (
            <Step
              key={index}
              title={
                <Popover
                  content={index === currentStepIndex ? message : null}
                  placement="bottom"
                  visible={index === currentStepIndex}
                >
                  <span>{stepTitles[index]}</span>
                </Popover>
              }
              icon={customIcon()}
            />
          ))}
        </Steps>
      </div>

      <div className="container-fluid card-bg-detail mt-5">
        {order.orderItems.map((item) => (
          !item.steelProduct ? (
            <div className="row order-details" key={item.orderItemId}>
              <div className="button-direction-orders d-flex justify-content-between">
                <div className="d-flex align-content-center ordersPage-imageGap-mobile align-items-center gap-4 p-4">
                  <img
                    src={`data:image/jpeg;base64,${item.imageData}`}
                    className="my-orders-product-img d-flex"
                    alt={item.title}
                    onClick={() => handleCardClick(item.productId)}
                  />
                  <div className="order-content">
                    <h5>{item.title}</h5>
                    <div className="d-flex gap-2 pt-1">
                      <Rate
                        disabled
                        allowHalf
                        value={item.numRatings}
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          paddingBottom: "5px",
                        }}
                      />
                      <p style={{ fontSize: "12px", color: "#1b3a57" }}>
                        {item.numRatings}
                      </p>
                      <p style={{ fontSize: "12px", color: "#1b3a57" }}>
                        ({item.totalRatings})
                      </p>
                    </div>

                    <p>Quantity: {item.quantity}</p>
                    <div className="d-flex align-items-center gap-2">

                      <p className="d-flex align-items-center"><INRrupee /> {item.discountedPrice}{" "}</p>
                      <span className="text-muted text-decoration-line-through d-flex align-items-center">
                        <INRrupee /> {item.price}
                      </span>
                      <span className="product-detail-discount">
                        {item?.discountPercentage}% off
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-5  d-flex flex-column orderDetails justify-content-center">
                  {order.orderStatus === "DELIVERED" && (
                    <button className="faq-get-in-touch-button orderDetails-review-button" onClick={() => handleReviewModal(item.productId)}>
                      Write a Review
                    </button>)
                  }

                </div>
              </div>
              <Divider style={{ marginLeft: '12px' }} />
            </div>
          ) : null
        ))}
        {Object.keys(groupedSteelProducts).map((productId, index) => (
          <div className="row order-details" key={productId}>
            <div className="button-direction-orders d-flex justify-content-between">
              <div className="d-flex align-content-center ordersPage-imageGap-mobile align-items-center gap-4 p-4">
                <img
                  src={`data:image/jpeg;base64,${groupedSteelProducts[productId][0].imageData}`}
                  className="my-orders-product-img d-flex"
                  alt={groupedSteelProducts[productId][0].title}
                />
                <div className="order-content">
                  <h5>{groupedSteelProducts[productId][0].title}</h5>
                  <div className="d-flex gap-2 pt-1">
                    <Rate
                      disabled
                      allowHalf
                      value={groupedSteelProducts[productId][0].numRatings}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        paddingBottom: "5px",
                      }}
                    />
                    <p style={{ fontSize: "12px", color: "#1b3a57" }}>
                      {groupedSteelProducts[productId][0].numRatings}
                    </p>
                    <p style={{ fontSize: "12px", color: "#1b3a57" }}>
                      ({groupedSteelProducts[productId][0].totalRatings})
                    </p>
                  </div>

                  <p>Quantity: {groupedSteelProducts[productId].reduce((acc, item) => acc + item.quantity, 0)}</p>
                  <div className="d-flex align-items-center gap-2">
                    <p cl><INRrupee /> {groupedSteelProducts[productId].reduce((acc, item) => acc + item.discountedPrice, 0)}{" "}</p>
                    <span className="text-muted text-decoration-line-through">
                      <INRrupee />{groupedSteelProducts[productId].reduce((acc, item) => acc + item.price, 0)}
                    </span>
                    <span className="product-detail-discount">
                      {groupedSteelProducts[productId][0].discountPercentage}% off
                    </span>
                  </div>

                  <button
                    className="view-more-button"
                    style={{
                      backgroundColor: "#f6f6f6",
                      border: "none",
                      color: "#1b3a57",
                      textDecoration: "underline"
                    }}
                    onClick={() => toggleExpand(productId)}
                  >
                    {expandedItems[productId] ? 'View Less' : 'View More'}
                    {expandedItems[productId] ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </button>
                </div>
              </div>
              <div className="mt-5 d-flex justify-content-center">
                {order.orderStatus === "DELIVERED" && (
                  <button className="faq-get-in-touch-button orderDetails-review-button" onClick={() => handleReviewModal(productId)}>
                    Write a Review
                  </button>)
                }
              </div>
            </div>
            {expandedItems[productId] && (
              <div className="steel-product-details px-5">
                <table className="table my-orders-steel-table">
                  <thead>
                    <tr>
                      <th>Steel Size</th>
                      <th>Quantity</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedSteelProducts[productId].slice()
                      .sort((a, b) => {
                        const sizeA = parseFloat(a.steelSize.match(/\d+/));
                        const sizeB = parseFloat(b.steelSize.match(/\d+/));
                        return sizeA - sizeB;
                      }).map((item) => (
                        <tr key={item.orderItemId}>
                          <td>{item.steelSize}</td>
                          <td>{item.quantity}</td>
                          <td><INRrupee />{item.discountedPrice}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {index !== Object.keys(groupedSteelProducts).length - 1 && <Divider />}
          </div>
        ))}
      </div>
      <button
        type="button"
        className="add-to-cart-button next-button-cart mb-3 "
        onClick={() => handleBack()}
      >
        <ArrowLeftOutlined className="mx-2" />
        Previous
      </button>
      <div>
        <Modal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={[]}
        >
          <div className="review-form">
            <h3 style={{ textAlign: "center" }}>Share your experience by leaving a review for our products</h3>
            <p>How would you describe your experience with our product?</p>
            <div className="d-flex justify-content-center align-content-center">
              <Rate
                value={value}
                onChange={(value) => setValue(value)}
                required
              />
            </div>
            <div className="form-group mb-2">
              <label>Review:</label>
              <Input.TextArea

                placeholder="write a review"
                value={formData.review}
                onChange={handleInputChange}
                style={{ backgroundColor: "#F6F6F6", resize: "none", height: "80px" }}
                name="review"
              />
            </div>
            <button
              key="submit"
              className="faq-get-in-touch-button"
              style={{ width: "100%" }}
              onClick={handleReview}
              type="primary"
            >
              Submit Review
            </button>
          </div>
        </Modal>
        <Modal
          visible={succesModalVisible}
          onCancel={() => setSuccesModalVisible(false)}
          footer={[]}
        >
          <div className="review-form">
            <div className="d-flex justify-content-center">
              <img src={star} alt="" />
            </div>
            <p> Thank You for submitting review</p>
            <div className="d-flex justify-content-center">
              <button className="faq-get-in-touch-button" onClick={redirectToShopPage}>
                Keep Browsing
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default OrderDetails;
