import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import { FrownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Instance from "../../AxiosConfig"; 
import { login, logout, setAuthState } from "../../states/reducers/AuthSlice"; 
import { useDispatch } from "react-redux";
import { clearCart } from "../../states/reducers/AddToCartSlice";
import { clearWishlist } from "../../states/reducers/wishlistSlice";

export const SignOut = () => {
  const [modalVisible, setModalVisible] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSuccessModalClose = () => {
    setModalVisible(false);
  };

  const handleSignOut = async () => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');

      const response = await Instance.post("/api/v1/auth/logout", null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      if (response.status === 200) {
        message.success("You have successfully logged out.");
        localStorage.removeItem("authToken");
        sessionStorage.removeItem("sessionId");
        dispatch(logout());
        dispatch(clearCart());
        dispatch(clearWishlist());
        navigate("/"); 
      }
    } catch (error) {
      message.error("Error during sign out.");
    } finally {
      setModalVisible(false);
    }
  };

  return (
    <>
      <div style={{ height: "400px" }}></div>
      <Modal
        visible={modalVisible}
        footer={null}
        onCancel={handleSuccessModalClose}
      >
        <div className="d-flex justify-content-center flex-column forgot-password-div">
          <FrownOutlined
            style={{ color: "#ce1b28", fontSize: "78px" }}
            className="d-flex justify-content-center"
          />
          <h2 className="text-center">Sure you want to Logout? </h2>
          <p
            style={{ color: "#54595e", textAlign: "center", fontSize: "18px" }}
          >
            Are you sure you want to Sign out this?
          </p>
          <div className="d-flex gap-4 justify-content-center">
            <Button
              key="submit"
              type="primary"
              className="faq-get-in-touch-button-signout"
              onClick={handleSuccessModalClose}
            >
              No,Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              className="faq-get-in-touch-button-signout"
              onClick={handleSignOut}
            >
              Yes,Continue
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
