import React, {useEffect, useState} from "react";
import smile from "../../Assets/SKImages/smile-icon.jpg";
import user from "../../Assets/SKImages/Users.png";
import trophy from "../../Assets/SKImages/Trophy.png";

export const WhyUs = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 2400);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 2400);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='container why-us-container'>
      <div className="row">
        <div className="why-us-heading">
          <h2 className="text-center"> Why Us</h2>
          <p style={{textAlign:'left'}} className="">
            Whether you're a first-time buyer or a seasoned professional, you'll
            find valuable information to help you make informed decisions and
            get the most out of your purchase
          </p>
        </div>
        <div className="experience-clients-div">
          <div className=" d-flex flex-column justify-content-center  experience-clients-column ">
            <div className="d-flex gap-2 experience-clients-column-heading justify-content-center mb-4">
              <img src={smile} className="why-us-icon" alt="" />
              <div className="d-flex flex-column justify-content-start">
                <h2 className="text-center why-us-count">183+</h2>
                <span className="why-us-happyclient">Happy Clients</span>
              </div>
            </div>
            <p className="why-us-paragraph">
              Customer-Centric Approach: We prioritize our customers' needs and
              work tirelessly to ensure their satisfaction.
            </p>
          </div>
          <div className=" d-flex flex-column justify-content-center  experience-clients-column ">
            <div className="d-flex gap-2 experience-clients-column-heading justify-content-center mb-4">
              <img src={user} className="why-us-icon" alt="" />
              <div className="d-flex flex-column justify-content-start">
                <h2 className="">23+</h2>
                <span className="why-us-happyclient">Years Experience</span>
              </div>
            </div>
            <p className="why-us-paragraph">
              Trusted Quality: Our products are trusted by builders and
              contractors across the nation for their reliability and
              performance.
            </p>
          </div>
          <div
            className=" d-flex flex-column justify-content-center"
            style={{ padding: "0px 20px" }}
          >
            <div className="d-flex gap-2 experience-clients-column-heading justify-content-center mb-4 ">
              <img src={trophy} className="why-us-icon" alt="" />
              <div className="d-flex flex-column justify-content-start">
                <h2 className="">17+</h2>
                <span className="why-us-happyclient"  >Awards Rewarded</span>
              </div>
            </div>
            <p className="why-us-paragraph">
              Nationwide Delivery: Our efficient logistics network ensures
              timely delivery of materials to your doorstep, no matter where you
              are located.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
