import "./HomeBanner.css";
import { ShoppingCartOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Instance from "../../AxiosConfig";
import { Rate, Spin, message } from "antd";
import { HeartOutlined, HeartFilled, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import INRrupee from "./INRrupee";
import { addWishlist,addWishlists,removeWishlist } from "../../states/reducers/wishlistSlice";
import { addItem,addItems } from "../../states/reducers/AddToCartSlice";
import { useDispatch } from "react-redux";
const Product = () => {
  const [loading, setLoading] = useState(true);
  const [cementProducts, setCementProducts] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);
  const [liked, setLiked] = useState([]);
  const [products, setProducts] = useState({});

  const dispatch=useDispatch()

  const navigate = useNavigate();

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 2400);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 2400);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleShopButton = (productType) => {
    navigate(`/shop?type=${productType}`);
  };

  const handleCardClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await Instance.get("/api/v1/products/grouped-by-category");
        setProducts(response.data);
        setLoading(false);
        console.log("fetched all products are:", response.data)
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  const addToCart = async (productId, cartItemDtos = []) => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem('sessionId', sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem('sessionId');
      }
      const url = `api/v1/cart/add/${productId}${sessionId ? `?sessionId=${sessionId}` : ''}`;
      const response = await Instance.post(
        url,
        cartItemDtos,
        {
          headers: {
            Authorization: `Bearer ${authToken}` 
          },
        }
      );
      if (response.status === 200) {
        message.success(response.data.message);
        console.log(response);
        dispatch(addItem(response.data))
        fetchCartCount();

      }
    }
    catch (error) {
      const errorMessage = error.response?.data?.message;
      message.error(errorMessage);
    }
    
  };
  
  const fetchCartCount = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.get(
        `/api/v1/cart/?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(addItems(response.data.cartItems))
      }
     
    } catch (error) {
      console.error("Error fetching cart count:", error);
       }
  };

  useEffect(() => {
    fetchCartCount(dispatch);
  },[dispatch]);
  
  const generateSessionId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  
  const fetchWishlistData = async () => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const url = `/api/v1/wishlist/?sessionId=${sessionId}`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        setWishlistData(response.data);
        dispatch(addWishlists(response.data));
        console.log("wishlistData",response.data)
      }
    } catch (error) {
      console.error(error?.response?.data?.message || "An error occurred");
    }
  };
  useEffect(() => {
    fetchWishlistData(dispatch); 
  }, [dispatch]);
  
  const addToWishlist = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem('sessionId', sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem('sessionId');
      }
      const url = `/api/v1/wishlist/products/${productId}${sessionId ? `?sessionId=${sessionId}` : ''}`;
      const response = await Instance.post(url, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("wishlist",response)
        message.success(response.data.message);
        dispatch(addWishlist(response.data));
        fetchWishlistData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred while adding to wishlist");
    }
  };

  const removeFromWishlist = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const url = `/api/v1/wishlist/product/${productId}?sessionId=${sessionId}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        message.success(response?.data?.message);
        dispatch(removeWishlist(productId));
        fetchWishlistData(); 
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };

 

  const renderCard = (card, index) => {
    const isLiked = wishlistData.some(
      (item) => item.productId === card.productId
    );

    const toggleLike = (e) => {
      e.stopPropagation();
      if (isLiked) {
        removeFromWishlist(card.productId);
      } else {
        addToWishlist(card.productId);
      }
    };
    const handleAddToCart = (e, product) => {
      e.stopPropagation();
      if (product.categoryTitle.toLowerCase() === "steel".toLowerCase()) {
        handleCardClick(product.productId);
      } else {
        addToCart(product.productId);
      }
    };

    
    return (
      <div key={index} className="products-card-main"  onClick={() => handleCardClick(card.productId)}>
       <div className="Heart-outlined" onClick={toggleLike}>
          {isLiked ? <HeartFilled style={{ color: "red" }} /> : <HeartOutlined />}
        </div>
        <div>
          <img
            src={`data:image/jpeg;base64,${card.imageData}`}
            alt={card.title}
            style={{ width: "200px", height: "200px" }}
          />
        </div>
        <div className="products-card-body">
          <Rate
            disabled
            allowHalf
            value={card.numRatings}
            style={{
              display: "flex",
              justifyContent: "start",
              paddingTop: "20px",
            }}
          />
          <h2>{card.title}</h2>
          <h3>{card.description}</h3>
          <div className="product-price d-flex gap-2 align-content-center align-items-center">
            <h3 className="price align-content-center align-items-center"><INRrupee/>{card.discountedPrice}</h3>
            <span className="original-price"><INRrupee/>{card.price}</span>
            <span className="home-product-detail-discount">
                    {card?.discountPercentage}% off
            </span>
          </div>
          <p className="product-brand"><span>Brand:</span> {card.brand}</p>
          <button className="add-to-cart-button mt-3" onClick={(e) => handleAddToCart(e, card)}>
            <ShoppingCartOutlined className="pe-2" />
            Add To Cart
          </button>
        </div>
      </div>
    );
  };

  

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div className='container product-container'>

{loading ? (
        <Spin size="large" />
      ) : (
        <>
      {Object.keys(products).map((category) => (
        <div key={category}>
         <div className="d-flex justify-content-between">
         <h5 className="pt-4 d-none d-md-block" style={{ textAlign: "left" }}>{category.charAt(0).toUpperCase() + category.slice(1)}</h5>
         <button className=" d-none d-md-block" style={{ backgroundColor: "#fff", border: "none", color: "#1b3a57" }} onClick={() => handleShopButton(category)}>View All</button>
         </div>
          {products[category].length > 0 ? (
            <div className="row d-none d-md-flex">
              {products[category].slice(0, 4).map((card, index) => (
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12" key={index}>
                  {renderCard(card, index)}
                </div>
              ))}
            </div>
          ) : (
            <div className="d-none d-md-block">No {category} products available</div>
          )}
        </div>
      ))}

<div className="d-md-none">
  {Object.keys(products).map((category) => (
    <div key={category}>
      <div className="d-flex justify-content-between">
        <h5 className="pt-4" style={{ textAlign: "left" }}>
          {category.charAt(0).toUpperCase() + category.slice(1)}
        </h5>
        <button
          style={{ backgroundColor: "#fff", border: "none", color: "#1b3a57" }}
          onClick={() => handleShopButton(category)}
        >
          View All
        </button>
      </div>

      {products[category].length > 0 ? (
      <Slider {...sliderSettings}>
        {products[category].slice(0, 4).map((card, index) => (
          <div key={index}>
            {renderCard(card, index)}
          </div>
        ))}
      </Slider>
      ): (
        <div>No {category} products available</div>
      )}
    </div>
  ))}
</div>
</>
      )}


    </div>
  );
};

// Custom PrevArrow component
const PrevArrow = ({ onClick }) => (
  <div className="custom-arrow custom-prev" onClick={onClick}>
    <LeftOutlined className="arrow-icon" />
  </div>
);

// Custom NextArrow component
const NextArrow = ({ onClick }) => (
  <div className="custom-arrow custom-next" onClick={onClick}>
    <RightOutlined className="arrow-icon" />
  </div>
);
export default Product;
