import React, { useEffect, useState } from "react";
import "./HomeBanner.css";
import greenHouse from "../../Assets/SKImages/sustainability.png";
import { useNavigate } from "react-router-dom";

const Sustainability = () => {
  const navigate=useNavigate();

  const handleabout=()=>{
    navigate("/about");
  }
  // const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 2400);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsLargeScreen(window.innerWidth >= 2400);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  return (
    <div
      className="container sustain-container text-center">
      <div className="sustainability-row row align-items-start">
        <div className="col-lg-6 col-xxl-4">
          <img src={greenHouse} alt="Green house" className="section-image" />
        </div>
        <div className="sustainability-col col-lg-6 col-xxl-7">
          <h2 className="section-head">ABOUT US</h2>
          <p className="section-para">
            At Builderneed, we are dedicated to providing top-quality
            building materials that meet the highest industry standards. With
            years of experience in the construction sector, we understand the
            importance of reliable and durable materials. Our extensive range of
            cements and steels is designed to cater to the diverse needs of
            builders, contractors, and homeowners. Committed to excellence, we
            strive to offer competitive prices, exceptional customer service,
            and timely delivery. Choose Builderneed for a solid
            foundation in every project.
          </p>
          <button className="d-flex justify-content-start faq-get-in-touch-button" onClick={handleabout}>
            Read More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sustainability;
