import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import mailIcon from "../Assets/SKImages/mail-icon.png";
import callIcon from "../Assets/SKImages/call-icon.png";
import contactIcon from "../Assets/SKImages/contact-icon.png";
import {
  FacebookFilled,
  InstagramFilled,
  XOutlined,
  LinkedinFilled,
} from "@ant-design/icons";
import Instance from "../AxiosConfig";

const ContactInfo = () => {
  const [storeInfo, setStoreInfo] = useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1600);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const fetchStoreInfo = async () => {
      try {
        const response = await Instance.get("/api/v1/store-info");
        console.log("Store Information", response.data);
        setStoreInfo(response.data);
      } catch (error) {
        console.error("Error fetching store info:", error);
      }
    };

    fetchStoreInfo();
  }, []);

  const formatAddress = (address) => {
    const parts = address.split(',').map(part => part.trim());

    let formattedAddress = '';
    let count = 0;

    parts.forEach((part, index) => {
      formattedAddress += part;
      if (index < parts.length - 1) {
        formattedAddress += ', ';
      }
      if ((index + 1) % 2 === 0 && index !== parts.length - 1) {
        formattedAddress += '<br />';
      }
    });

    return formattedAddress;
  };

  return (
    <>
      <div className='container info-container d-flex'>
        <div className="col-lg-4 contact-section">
          <img src={mailIcon} className="contact-icons" alt="Mail Icon" />
          <h5>Business Hours</h5>
          <ul>
            {/* <li>Monday to Saturday: {storeInfo?.openingHours} AM- {storeInfo?.closingHours} PM</li> */}
            <li>Monday to Saturday : </li>
            <li style={{ listStyle: "none" }}>9:00 AM - 6:00 PM</li>
            <li>Sunday : Closed</li>
          </ul>
        </div>
        <div className="col-lg-4 contact-section">
          <img src={callIcon} className="contact-icons" alt="Call Icon" />
          <h6>Email Us</h6>
          {/* <p>{storeInfo?.email}</p> */}
          <p>info@skcementandsteel.com</p>
          <h6>Sales Inquiries</h6>
          {/* <p>{storeInfo?.phoneNumber}</p> */}
          <p>+91 9449551297</p>
          <h6>Address</h6>
          {/* <div
            className="address" style={{textAlign: "left"}}
            dangerouslySetInnerHTML={{
              __html: formatAddress(storeInfo?.address || ""),
            }}
          /> */}

          <div className="d-flex mt-2 flex-column footer-address-text">
            <p>383, 9th Main Rd, 7th Sector,</p>
            <p> HSR Layout, Bengaluru,</p>
            <p> Karnataka 560102</p>
          </div>
        </div>
        <div className="col-lg-4 contact-last-section">
          <img src={contactIcon} className="contact-icons" alt="Contact Icon" />
          <h5>Follow Us on</h5>
          <div className="contact-social-links">
            <ul class="contact-social-icons">
              <li class="social-icon">
                <a href="#" class="social-link">
                  <i class="fab fa-facebook-f icon"></i>
                </a>
              </li>
              <li class="social-icon">
                <a href="#" class="social-link"><i class="fab fa-twitter icon"></i></a>
              </li>
              <li class="social-icon">
                <a href="#" class="social-link"><i class="fab fa-linkedin-in icon"></i></a>
              </li>
              <li class="social-icon">
                <a href="#" class="social-link"><i class="fab fa-instagram icon bold-icon"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15556.56287260775!2d77.635236!3d12.908371!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTLCsDU0JzMwLjciTiA3N8KwMzgnMDcuMCJF!5e0!3m2!1sen!2sin!4v1717055829678!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

    </>
  );
};

export default ContactInfo;
