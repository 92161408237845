import React, { useState, useEffect } from 'react';
import { Steps } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import "./ShoppingCart.css";
import ShoppingStep1 from './ShoppingStep1';
import ShoppingStep2 from './ShoppingStep2';
import { PaymentOptions } from './PaymentOptions';
import RecentlyViewedCarasoul from '../Home/RecentlyViewedCarasoul';
import SteelCarousel from '../Home/SteelCarousel';

const { Step } = Steps;

const StepProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(null);  
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [orderId, setOrderId] = useState(null);

  const handleCartStatus = (status) => {
    setIsCartEmpty(status);
  };

  useEffect(() => {
    const path = location.pathname;
    const pathParts = path.split('/');
    const id = pathParts[pathParts.length - 1];

    let newCurrent = 0; 
    if (path.startsWith('/cart')) {
      newCurrent = 0;
    } else if (path.startsWith('/address')) {
      newCurrent = 1;
    } else if (path.startsWith('/payment-page/')) {
      newCurrent = 2;
      setOrderId(id);
    }

    setCurrent(newCurrent);

  }, [location.pathname]);

 
  const handleNext = () => {
    if (current < steps.length - 1) {
      setCurrent(current + 1);
    }
  };

  const handlePrevious = () => {
    if (current > 0) {
      setCurrent(current - 1);
    }
  };

  const steps = [
    {
      title: 'Shopping Cart',
      content: <ShoppingStep1 next={handleNext} updateCartStatus={handleCartStatus} />,
    },
    {
      title: (
        <div className="shipping-title">
          <span className="shipping-part">Shipping </span> <span className="checkout-part"></span>
        </div>
      ),
      content: (
        <ShoppingStep2
          next={(orderId) => { 
            setOrderId(orderId); 
            handleNext(); 
          }}
          prev={handlePrevious}
        />
      ),
    },
    {
      title: 'Confirmation',
      content: <PaymentOptions orderId={orderId} />,
    },
  ];

  return (
    <>
      <div className='container'>
        {!isCartEmpty && current !== null && (  
          <div>
            <Steps size="small" current={current} className="steps-antd  step-process-media mt-4" style={{ width: "50%", margin: "auto", height: "100px"}}>
              {steps.map((item, index) => (
                <Step key={index} title={item.title} className='item-title' />
              ))}
            </Steps>
          </div>
        )}
        <div className="steps-content">
          {current !== null && steps[current]?.content}  
        </div>
      </div>
      <RecentlyViewedCarasoul />
      <SteelCarousel />
    </>
  );
};

export default StepProcess;
