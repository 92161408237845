import React, { useState, useEffect } from "react";
import "./header.css";
import Instance from "../../AxiosConfig";
import {
  HomeOutlined,
  MailFilled,
  MenuOutlined,
  CloseOutlined,
  SearchOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  HeartOutlined,
  RightOutlined,
  ShopOutlined,
} from "@ant-design/icons";
// import { FaHome } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import { FaGlobeAsia, FaPhoneAlt } from "react-icons/fa";
import { useMemo } from "react";
import { Badge } from "antd";
import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
import { Input } from "antd";
import logo1 from "../../Assets/SKImages/header-logo-new.png";
import newlogo from "../../Assets/SKImages/new-logo-croped.png"
import { useSelector, useDispatch } from "react-redux";
import { setAuthState } from "../../states/reducers/AuthSlice";
import { FaQuestion } from "react-icons/fa6";
import { BiCategory } from "react-icons/bi";
import { logo } from "../../Assets/SKImages/white-bg-logo.png";
import { addItems } from "../../states/reducers/AddToCartSlice";
import INRrupee from "../../Components/Home/INRrupee";

const MainHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { Search } = Input;
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.userData?.fullName);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [productTitles, setProductTitles] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [productSuggestions, setProductSuggestions] = useState([]);
  const [storeInfo, setStoreInfo] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const handleCart = () => {
    navigate("/cart");
    toggleMenu();
  };
  const handleLogo = () => {
    navigate("/");
  };
  const handleWishlist = () => {
    if (isLoggedIn) {
      navigate("/profile/wishlist");
    } else {
      navigate("/wishlist");
    }
    toggleMenu();
  };
  const handleCategoryClick = (productType) => {
    navigate(`/shop?type=${productType}`);
  };

  useEffect(() => {
    const userToken = localStorage.getItem("authToken");
    dispatch(setAuthState({ isLoggedIn: !!userToken, userData: null }));
  }, [dispatch]);

  const wishlistsData = useSelector((state) => state.wishlist.wishlistsData);
  const favoritesCount = wishlistsData.length;

  const CartData = useSelector((state) => state.cart.AddToCartData);
  const cartCount = CartData.cartItems.length;
  const totalDiscountedPrice = CartData.totalDiscountedPrice;


  const fetchWishlistData = async () => {
    try {
      const sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.get(
        `/api/v1/wishlist/?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error fetching wishlist data:", error);
    }
  };
  useEffect(() => {
    fetchWishlistData(dispatch);
  }, [dispatch]);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No auth token found");
      }
      const response = await Instance.get("/api/v1/user/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const userData = response.data;
        dispatch(setAuthState({ isLoggedIn: true, userData }));
      } else {
        dispatch(setAuthState({ isLoggedIn: false, userData: null }));
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      dispatch(setAuthState({ isLoggedIn: false, userData: null }));
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [dispatch]);

  const fetchCartCount = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.get(
        `/api/v1/cart/?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      console.log('API Response:', response);
      console.log('Cart Items:', response.data.cartItems);

      // dispatch(addItems(response.data.cartItems));
    } catch (error) {
      console.error("Error fetching cart count:", error);
    }
  };

  useEffect(() => {
    fetchCartCount(dispatch);
  }, [dispatch]);

  const fetchCategoryData = async () => {
    try {
      const url = "/api/v1/categories/";
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        setCategoryData(response.data);
      }
    } catch (error) {
      console.error(error?.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchCategoryData();
  }, []);

  useEffect(() => {
    const fetchStoreInfo = async () => {
      try {
        const response = await Instance.get("/api/v1/store-info");
        console.log("contact", response.data);
        setStoreInfo(response.data);
      } catch (error) {
        console.error("Error fetching store info:", error);
      }
    };

    fetchStoreInfo();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const getActiveMenuItem = (path) => {
    if (path === "/") return "Home";
    else if (path === "/shop") return "Shop";
    else if (path === "/about") return "About Us";
    else if (path === "/contact-us") return "Contact Us";
    else if (path === "/faq") return "FAQs";
    else return "";
  };

  const [activeMenu, setActiveMenu] = useState(
    getActiveMenuItem(location.pathname)
  );

  useEffect(() => {
    setActiveMenu(getActiveMenuItem(location.pathname));
  }, [location.pathname]);

  const fetchProductSuggestions = async (keywords) => {
    try {
      const response = await Instance.get(`/api/v1/products/search/${keywords}`);
      console.log("products", response);
      if (response.status === 200) {
        const data = response?.data;
        if (data && Array.isArray(data)) {
          const suggestions = [];
          const seen = new Set(); // Create a Set to track seen values

          data.forEach((item) => {
            // Normalize title
            if (item.title) {
              const normalizedTitle = item.title.trim().toLowerCase();
              if (!seen.has(normalizedTitle)) {
                suggestions.push({ type: "title", value: item.title });
                seen.add(normalizedTitle); // Mark this title as seen
              }
            }
            // Normalize description
            if (item.description) {
              const normalizedDescription = item.description.trim().toLowerCase();
              if (!seen.has(normalizedDescription)) {
                suggestions.push({ type: "description", value: item.description });
                seen.add(normalizedDescription); // Mark this description as seen
              }
            }
            // Normalize brand
            if (item.brand) {
              const normalizedBrand = item.brand.trim().toLowerCase();
              if (!seen.has(normalizedBrand)) {
                suggestions.push({ type: "brand", value: item.brand });
                seen.add(normalizedBrand); // Mark this brand as seen
              }
            }
          });

          // Deduplicate suggestions based on value across all types
          const uniqueSuggestions = Array.from(
            new Map(suggestions.map(item => [item.value.trim().toLowerCase(), item])).values()
          );

          setProductSuggestions(uniqueSuggestions);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    fetchProductSuggestions();
  }, []);

  const handleProductSearchChange = (event) => {
    const keyword = event.target.value;
    setSearchText(keyword);
    if (keyword === "") {
      setShowSuggestions(false);
    } else {
      setShowSuggestions(true);
      fetchProductSuggestions(keyword);
    }
  };

  const normalizeString = (str) => str.replace(/\s+/g, "").toLowerCase();

  const productSuggestion = useMemo(() => {
    if (searchText.trim() === "") {
      return [];
    }
    const normalizedKeyword = normalizeString(searchText);
    return productTitles.filter((term) =>
      normalizeString(term).includes(normalizedKeyword)
    );
  }, [searchText]);

  const handleSuggestionClick = (suggestion) => {
    setSearchText(suggestion.value);  // Set the selected suggestion's value to searchText
    handleSearch(suggestion.value);   // Trigger search with the selected value
  };

  // Modify handleSearch to accept an optional search query
  const handleSearch = (query) => {
    const searchQuery = (query && query.length > 0) ? query : searchText; // Use the selected suggestion value or the current searchText
    navigate(`/filtered-products?query=${searchQuery}`);
    setShowSuggestions(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <>
      <div className={`sticky-header ${isSticky ? "visible" : ""}`}>
        <div
          className="d-flex gap-5 align-content-center justify-content-center py-0"
          style={{ backgroundColor: "#e6effd" }}
        >
          <nav className="navbar navbar-expand-lg ">
            <div className="container header-menu ">
              <div
                className="collapse navbar-collapse d-flex"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0 d-flex header-sticky">
                  <NavLink
                    className="nav-link header-menu-items"
                    to="/"
                    activeClassName="active"
                    exact
                  >
                    Home
                  </NavLink>

                  <NavLink
                    className="nav-link header-menu-items"
                    to="/shop"
                    activeClassName="active"
                  >
                    Shop
                  </NavLink>

                  <NavLink
                    className="nav-link header-menu-items"
                    to="/about"
                    activeClassName="active"
                  >
                    About Us
                  </NavLink>

                  <NavLink
                    className="nav-link header-menu-items"
                    to="/contact-us"
                    activeClassName="active"
                  >
                    Contact Us
                  </NavLink>

                  <NavLink
                    className="nav-link header-menu-items"
                    to="/faq"
                    activeClassName="active"
                  >
                    FAQs
                  </NavLink>
                </ul>
                <ul className="navbar-nav mb-2 mb-lg-0 d-flex  navbar-right align-content-center">
                  <li className="nav-item dropdown">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="all-categories-header mt-1 p-2 d-flex align-items-center justify-content-center"
                        style={{ fontWeight: "600" }}
                        id="navbarDropdown"
                      >
                        All Categories
                      </Dropdown.Toggle>

                      <Dropdown.Menu aria-labelledby="navbarDropdown">
                        {categoryData.map((category, index) => (
                          <React.Fragment key={category.id}>
                            <Dropdown.Item
                              as="a"
                              href="#"
                              className="d-flex justify-content-between"
                              onClick={() =>
                                handleCategoryClick(category.categoryTitle)
                              }
                            >
                              {category.categoryTitle}
                              <RightOutlined style={{ fontSize: "12px" }} />
                            </Dropdown.Item>
                            {index < categoryData.length - 1 && (
                              <Dropdown.Divider />
                            )}
                          </React.Fragment>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>

                  <li className="nav-item pt-2 align-items-center">
                    <Badge
                      count={favoritesCount}
                      style={{
                        fontSize: "12px",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                      size="small"
                    >
                      <HeartOutlined
                        className="wishlist-icon-header"
                        shape="circle"
                        size="large"
                        onClick={handleWishlist}
                      />
                    </Badge>
                  </li>
                  <li className="nav-item pt-2 d-flex gap-2 ">
                    <Badge
                      count={cartCount}
                      style={{
                        fontSize: "12px",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                      size="small"
                    >
                      <ShoppingCartOutlined
                        className="cart-icon-header"
                        shape="circle"
                        size="large"
                        onClick={handleCart}
                      />
                    </Badge>
                    <h6 className="pt-2 d-flex align-items-center gap-1"><INRrupee /> {totalDiscountedPrice}</h6>
                  </li>

                  <li style={{}}>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                        className="d-flex align-items-center gap-2"
                        style={{
                          fontWeight: "600",
                          border: "1px solid #1b3a57",
                          borderRadius: "20px",
                          padding: "7px",
                          backgroundColor: "#1b3a57",
                          color: "#fff",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #fff",
                            borderRadius: "50%",
                          }}
                        >
                          <UserOutlined className="profile-icon-header" />
                        </div>
                        {isLoggedIn ? userData : "Login/Register"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {isLoggedIn ? (
                          <>
                            <Dropdown.Item href="/profile">
                              Profile
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="/profile/sign-out">
                              Logout
                            </Dropdown.Item>
                          </>
                        ) : (
                          <>
                            <Dropdown.Item href="/signin">Login</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="/register">
                              Register
                            </Dropdown.Item>
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className="container-fluid desktop-header">
        <div className="d-flex justify-content-around position-relative" >
          <img
            // src={logo1}
            src={newlogo}
            onClick={handleLogo}
            // style={{width:'200px', height:'auto'}}
            className="header-logo mt-3 "
            alt=""
          />
          <div className="d-flex my-4 align-items-center">
            <Input
              placeholder="Search for Products"
              value={searchText}
              onChange={handleProductSearchChange}
              onKeyDown={handleKeyDown} // Add the key down event handler
              className="search-input-table "

            />
            <div className="search-button-header mb-1 z-0">
              <button onClick={handleSearch}>
                <SearchOutlined />
              </button>
            </div>
          </div>
          {showSuggestions && (
            <div className="Homepredicted-search-card position-absolute" style={{ top: "75%", left: "57%" }}>
              {productSuggestions.length > 0 && (
                <ul>
                  {productSuggestions.map((item, index) => (
                    <li
                      className="predicted-search-link"
                      key={index}
                      onClick={() => {
                        handleSuggestionClick(item)
                      }}
                    >
                      <div className="d-flex gap-3">
                        <SearchOutlined />
                        {item.value}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          <div className="d-flex justify-content-end gap-4 pt-4">
            <div className="d-flex gap-2 header-contact-details">
              <div>
                <FaPhoneAlt className="header-icons-phone" />
              </div>
              <div style={{ textAlign: "left" }}>
                <h3>Phone Number</h3>
                {/* <p>{storeInfo?.phoneNumber}</p> */}
                <p>+91 9449551297</p>
              </div>
            </div>
            <div className="d-flex gap-2 header-contact-details">
              <div>
                <MailFilled className="header-icons" />
              </div>
              <div style={{ textAlign: "left" }}>
                <h3>Email Us Here</h3>
                <p>info@skcementandsteel.com</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-5 align-content-center justify-content-center py-0 "
          style={{ backgroundColor: "#e6effd" }}
        >
          <nav className="navbar navbar-expand-lg ">
            <div className="container header-menu">
              <div
                className="collapse navbar-collapse d-flex gap-3"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0  header-desktop">
                  <NavLink
                    className="nav-link header-menu-items"
                    to="/"
                    activeClassName="active"
                    exact
                  >
                    Home
                  </NavLink>

                  <NavLink
                    className="nav-link header-menu-items"
                    to="/shop"
                    activeClassName="active"
                  >
                    Shop
                  </NavLink>

                  <NavLink
                    className="nav-link header-menu-items"
                    to="/about"
                    activeClassName="active"
                  >
                    About Us
                  </NavLink>

                  <NavLink
                    className="nav-link header-menu-items"
                    to="/contact-us"
                    activeClassName="active"
                  >
                    Contact Us
                  </NavLink>

                  <NavLink
                    className="nav-link header-menu-items"
                    to="/faq"
                    activeClassName="active"
                  >
                    FAQs
                  </NavLink>
                </ul>
                <ul className="navbar-nav mb-2 mb-lg-0 d-flex align-content-center  navbar-right">
                  <li className="nav-item dropdown">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="all-categories-header mt-1 p-2 d-flex align-items-center justify-content-center"
                        style={{ fontWeight: "600" }}
                        id="navbarDropdown"
                      >
                        All Categories
                      </Dropdown.Toggle>

                      <Dropdown.Menu aria-labelledby="navbarDropdown">
                        {categoryData.map((category, index) => (
                          <React.Fragment key={category.id}>
                            <Dropdown.Item
                              as="a"
                              href="#"
                              className="d-flex justify-content-between"
                              onClick={() =>
                                handleCategoryClick(category.categoryTitle)
                              }
                            >
                              {category.categoryTitle}
                              <RightOutlined style={{ fontSize: "12px" }} />
                            </Dropdown.Item>
                            {index < categoryData.length - 1 && (
                              <Dropdown.Divider />
                            )}
                          </React.Fragment>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>

                  <li className="nav-item pt-2 align-items-center">
                    <Badge
                      count={favoritesCount}
                      style={{
                        fontSize: "12px",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                      size="small"
                    >
                      <HeartOutlined
                        className="wishlist-icon-header"
                        shape="circle"
                        size="large"
                        onClick={handleWishlist}
                      />
                    </Badge>
                  </li>
                  <li className="nav-item pt-2 d-flex gap-2 ">
                    <Badge
                      count={cartCount}
                      style={{
                        fontSize: "12px",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                      size="small"
                    >
                      <ShoppingCartOutlined
                        className="cart-icon-header"
                        shape="circle"
                        size="large"
                        onClick={handleCart}
                      />
                    </Badge>
                    <h6 className="pt-2 d-flex align-items-center gap-1"><INRrupee /> {totalDiscountedPrice}</h6>
                  </li>
                  <li style={{}}>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                        className="d-flex align-items-center gap-2"
                        style={{
                          fontWeight: "600",
                          border: "1px solid #1b3a57",
                          borderRadius: "20px",
                          padding: "7px",
                          backgroundColor: "#1b3a57",
                          color: "#fff",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #fff",
                            borderRadius: "50%",
                          }}
                        >
                          <UserOutlined className="profile-icon-header" />
                        </div>
                        {isLoggedIn ? userData : "Login/Register"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {isLoggedIn ? (
                          <>
                            <Dropdown.Item href="/profile">
                              Profile
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="/profile/sign-out">
                              Logout
                            </Dropdown.Item>
                          </>
                        ) : (
                          <>
                            <Dropdown.Item href="/signin">Login</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="/register">
                              Register
                            </Dropdown.Item>
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="container-fluid mobile-header">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img
                src={newlogo}
                onClick={handleLogo}
                className="mobile-logo"
                alt="Logo"
              />
            </a>
            <button
              className={`menu-button ${isMenuOpen ? "open" : ""}`}
              onClick={toggleMenu}
            >
              <MenuOutlined />
            </button>
            <div
              className={`menu-overlay ${isMenuOpen ? "open" : ""}`}
              onClick={toggleMenu}
            ></div>
            <div className={`menu-content ${isMenuOpen ? "open" : ""}`}>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <img
                  src={newlogo}
                  // className="mobile-logo"
                  alt=""
                  style={{
                    width: "130px",
                    height: "auto",
                    marginTop: "0px",
                  }}
                />
                <button className="close-button" onClick={closeMenu}>
                  <CloseOutlined />
                </button>
              </div>
              <ul className="navbar-nav mb-2 mb-lg-0 d-flex gap-3">
                <li style={{}}>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="primary"
                      id="dropdown-basic"
                      className="d-flex align-items-center gap-2"
                      style={{
                        fontWeight: "600",
                        border: "1px solid #1b3a57",
                        borderRadius: "20px",
                        padding: "7px",
                        backgroundColor: "#1b3a57",
                        color: "#fff",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "50%",
                        }}
                      >
                        <UserOutlined className="profile-icon-header" />
                      </div>
                      {isLoggedIn ? userData : "Login/Register"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {isLoggedIn ? (
                        <>
                          <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item href="/profile/sign-out">
                            Logout
                          </Dropdown.Item>
                        </>
                      ) : (
                        <>
                          <Dropdown.Item href="/signin">Login</Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item href="/register">
                            Register
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <NavLink
                  className="nav-link header-menu-items"
                  to="/"
                  activeClassName="active"
                  onClick={closeMenu}
                  exact
                >
                  Home
                </NavLink>

                <NavLink
                  className="nav-link header-menu-items"
                  to="/shop"
                  activeClassName="active"
                  onClick={closeMenu}
                >
                  Shop
                </NavLink>

                <NavLink
                  className="nav-link header-menu-items"
                  to="/about"
                  activeClassName="active"
                  onClick={closeMenu}
                >
                  About Us
                </NavLink>


                <li className="nav-item dropdown">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="all-categories-header mt-1 p-2 d-flex align-items-center justify-content-center"
                      style={{ fontWeight: "600" }}
                      id="navbarDropdown"
                    >
                      All Categories
                    </Dropdown.Toggle>

                    <Dropdown.Menu aria-labelledby="navbarDropdown">
                      {categoryData.map((category, index) => (
                        <React.Fragment key={category.id}>
                          <Dropdown.Item
                            as="a"
                            href="#"
                            className="d-flex justify-content-between"
                            onClick={() => {
                              handleCategoryClick(category.categoryTitle);
                              closeMenu();
                            }}
                          >
                            {category.categoryTitle}
                            <RightOutlined style={{ fontSize: "12px" }} />
                          </Dropdown.Item>
                          {index < categoryData.length - 1 && (
                            <Dropdown.Divider />
                          )}
                        </React.Fragment>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <NavLink
                  className="nav-link header-menu-items"
                  to="/contact-us"
                  activeClassName="active"
                  onClick={closeMenu}
                >
                  Contact Us
                </NavLink>

                <NavLink
                  className="nav-link header-menu-items"
                  to="/faq"
                  activeClassName="active"
                  onClick={closeMenu}
                >
                  FAQs
                </NavLink>
                <div className="">
                  <li className="nav-item wishlist-cart-mobile pt-2 align-items-center d-flex  gap-3" onClick={handleWishlist}>
                    <Badge
                      count={favoritesCount}
                      style={{
                        fontSize: "12px",
                        backgroundColor: "#1b3a57",
                        color: "#fff",
                      }}
                      size="small"
                    >
                      <button
                        onClick={handleWishlist}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          padding: "0",
                        }}
                      >
                        <div className="d-flex gap-3 align-items-center">
                          <HeartOutlined
                            className="wishlist-icon-mobile-header "
                            shape="circle"
                            size="large"
                          />
                        </div>
                      </button>
                    </Badge>
                    <div>Wishlist</div>
                  </li>
                  <li className="nav-item wishlist-cart-mobile pt-2 mt-3  d-flex gap-3 " onClick={handleCart}>
                    <Badge
                      count={cartCount}
                      style={{
                        fontSize: "12px",
                        backgroundColor: "#1b3a57",
                        color: "#fff",
                      }}
                      size="small"
                    >
                      <button
                        onClick={handleCart}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          padding: "0",
                        }}
                      >
                        <div className="d-flex gap-3">
                          <ShoppingCartOutlined
                            className="wishlist-icon-mobile-header"
                            shape="circle"
                            size="large"
                          />
                        </div>
                      </button>
                    </Badge>
                    <h6 className="pt-2 d-block">
                      <INRrupee /> {totalDiscountedPrice}
                    </h6>
                  </li>
                </div>

              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default MainHeader;
