import React, { useState, useEffect } from "react";
import Instance from "../../AxiosConfig";
import { Rate, message } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { HeartOutlined, HeartFilled, LeftOutlined, RightOutlined} from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick"; // Import react-slick for carousel
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './HomeBanner.css';
import INRrupee from "./INRrupee";
import { addWishlist,addWishlists,removeWishlist } from "../../states/reducers/wishlistSlice";
import { useDispatch } from "react-redux";


const RecentlyViewedCarasoul = () => {
 
  const [liked, setLiked] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const handleShopButton = (productType) => {
      navigate(`/shop?type=${productType}`);
  };
  const [containerClass, setContainerClass] = useState("container");

  useEffect(() => {
    // Function to handle resizing and update the container class
    const handleResize = () => {
      if (window.innerWidth < 1500) {
        setContainerClass("container-fluid");
      } else {
        setContainerClass("container");
      }
    };

    // Set the initial class on component mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleLike = async (index, productId) => {
    const newLiked = [...liked];
    newLiked[index] = !newLiked[index];
    setLiked(newLiked);
    if (newLiked[index]) {
        addToWishlist(productId);
    }
  };

  const addToWishlist = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem('sessionId', sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem('sessionId');
      }
      const url = `/api/v1/wishlist/products/${productId}${sessionId ? `?sessionId=${sessionId}` : ''}`;
      const response = await Instance.post(url, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        message.success("Product added to wishlist successfully");
        dispatch(addWishlist(response.data))
        fetchWishlistData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred while adding to wishlist");
    }
  };

  const removeFromWishlist = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const url = `/api/v1/wishlist/product/${productId}?sessionId=${sessionId}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        message.success(response?.data?.message);
        dispatch(removeWishlist(productId));
        fetchWishlistData(); 
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };

  const addToCart = async (productId, cartItemDtos = []) => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem('sessionId', sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem('sessionId');
      }
      const url = `api/v1/cart/add/${productId}${sessionId ? `?sessionId=${sessionId}` : ''}`;
      const response = await Instance.post(
        url,
        cartItemDtos,
        {
          headers: {
            Authorization: `Bearer ${authToken}` 
          },
        }
      );
      if (response.status === 200) {
        message.success(response.data.message);
        console.log(`Product ${productId} added to sessionId: ${sessionId}`);
        console.log(response);
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
        'An error occurred while adding to cart'
      );
      console.log( "asdfg",error?.response )
    }
  };

  const [steelProducts, setSteelProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchWishlistData = async () => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const url = `/api/v1/wishlist/?sessionId=${sessionId}`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        setWishlistData(response.data);
        dispatch(addWishlists(response.data))
        console.log("wishlistData",response.data)
      }
    } catch (error) {
      console.error(error?.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchWishlistData(dispatch); 
  }, [dispatch]);

  const fetchSteelProducts = async () => {
    setLoading(true);
    try {
      const url = "/api/v1/products/category/title/steel";
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("Cement products fetched successfully:", response.data);
        setSteelProducts(response.data);
        setLiked(Array(response.data.length).fill(false));
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSteelProducts();
  }, []);
  const generateSessionId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  const handleCardClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };

  const renderCard = (card, index) => {
    const isLiked = wishlistData.some(
      (item) => item.productId === card.productId
    );

    const toggleLike = (e) => {
      e.stopPropagation();
      if (isLiked) {
        removeFromWishlist(card.productId);
      } else {
        addToWishlist(card.productId);
      }
    };
  

    return (
    <div key={index} className='products-card-main recently-viewed-steel-card'  onClick={() => handleCardClick(card.productId)}>
         <div className="Heart-outlined" onClick={toggleLike}>
          {isLiked ? <HeartFilled style={{ color: "red" }} /> : <HeartOutlined />}
        </div>
        <div className="d-flex justify-content-center"> 
        <img
              src={`data:image/jpeg;base64,${card.imageData}`}
              alt={card.title}
              style={{ width: "150px", height: "150px"}}
            />
        </div>
        <div className='products-card-body'>
            {/* <Rate
                disabled
                defaultValue={card.numRatings}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingTop: "20px",
                }}
              /> */}
            <h2>{card.title}</h2>
            {/* <h3>{card.description}</h3> */}
            <div className="product-price d-flex gap-2">
                <h3 className="price"><INRrupee/>{card.discountedPrice}</h3>
                <span className="original-price"><INRrupee/>{card.price}</span>
                <span className="home-product-detail-discount">
                    {card?.discountPercentage}% off
            </span>
            </div>
            <p className="product-brand"><span>Brand:</span> {card.brand}</p>
            <div className="d-flex pt-3 ">
              <Rate
                disabled
                allowHalf
                value={card.numRatings}
                style={{
                  display: "flex",
                  fontSize: "13px",
                  justifyContent: "start",
                }}
              />
              <span style={{ fontSize: "12px" }}>({card.numRatings}/{5})</span> <span style={{marginTop:'-2px'}}>|</span> <span style={{ fontSize: "12px" }}>{card.totalReviews} Reviews</span>
            </div>
            <button className='add-to-cart-button mt-3 ' onClick={() => handleCardClick(card.productId)}>
                <ShoppingCartOutlined className='pe-2' />
                Add To Cart
            </button>
        </div>
    </div>
    )
  };

  // Slider settings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1025,  // For screens below 1500px
        settings: {
          slidesToShow: 4,  // Show 5 slides when below 1500px
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      }, 
      {
        breakpoint: 900,  // For screens below 1500px
        settings: {
          slidesToShow: 3,  // Show 5 slides when below 1500px
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 1600,  // For screens below 1500px
        settings: {
          slidesToShow: 5,  // Show 5 slides when below 1500px
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,  // For mobile screens below 768px
        settings: {
          slidesToShow: 3,  // Show 1 slide for mobile screens
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 500,  // For mobile screens below 768px
        settings: {
          slidesToShow: 1,  // Show 1 slide for mobile screens
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
    ],
  };


  return (
    <div className={`${containerClass} mx-auto product-container p-5`} style={{ overflow: "hidden" }}>
      <div>
        <h2 className="d-flex justify-content-center product-head">YOU MAY ALSO LIKE</h2>
      </div>
      <Slider {...sliderSettings}>
        {steelProducts.slice(0, 4).map(renderCard)}
      </Slider>
    </div>
  );
};

// Custom PrevArrow component
const PrevArrow = ({ onClick }) => (
  <div className="custom-arrow custom-prev arrow-icons-left " onClick={onClick}>
    <LeftOutlined className="arrow-icon" />
  </div>
);

// Custom NextArrow component
const NextArrow = ({ onClick }) => (
  <div className="custom-arrow custom-next arrow-icons-right" onClick={onClick}>
    <RightOutlined className="arrow-icon" />
  </div>
);

export default RecentlyViewedCarasoul;
