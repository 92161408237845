import React, {useEffect, useState} from "react";
import icon1 from "../../Assets/icons/cart-icon.png";
import icon2 from "../../Assets/icons/order-icon.png";
import icon3 from "../../Assets/icons/delivery-icon.png";
import curve from "../../Assets/icons/joint-curve.png";

export const HowItWorks = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 2400);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 2400);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
      <div className='container how-it-works-container'>
      <div className="row">
        <div className="why-us-heading">
          <h2 className="text-center">How It Works</h2>
          <p className="how-it-works-paragraph">
            Browse, order, and receive top-quality construction materials
            with Builderneed's streamlined process
          </p>
        </div>
        <div className=" how-it-works-row">
          <div className="how-it-works-column">
            <div className="d-flex justify-content-center">
              <img src={icon1} alt="" />
            </div>
            <h2>Browse And Select</h2>
            <div style={{width:"250px"}}>
            <p className="how-it-works-paragraph">
              Explore our extensive range of high-quality cement, steel, and
              construction materials on our website. Select  products that
              meet your project requirements.
            </p>
            </div>
            
          </div>
            <div>
            <img src={curve} className="joint-curve-img" alt="" />

            </div>
          <div className=" how-it-works-column">
            <div className="d-flex justify-content-center">
              <img src={icon2} alt="" />
            </div>
            <h2>Place Your Order</h2>
            <div style={{width:"250px"}}>
            <p className="how-it-works-paragraph">
            Add your chosen items to the cart and proceed to checkout. Enter your delivery details, review your order,
             and make a secure payment through our various available options
            </p>
            </div>
          </div>
          <div className="d-flex justify-content-center align-content-center">
            <img src={curve} className="joint-curve-img" alt="" />
            </div>
          <div className=" how-it-works-column">
            <div className="d-flex justify-content-center">
              <img src={icon3} alt="" />
            </div>
            <h2>Delivery to Your Doorstep</h2>
            <div style={{width:"250px"}}>
            <p className="how-it-works-paragraph">
            Once your order is confirmed, our efficient logistics team will process it promptly. 
            We ensure timely delivery of your materials 
            straight to your construction site, anywhere in the nation
            </p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};
