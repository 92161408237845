import React,{useState,useEffect} from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { ShoppingOutlined, HeartOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import '../Components/Profile/profile.css';
import Instance from "../AxiosConfig";
import {  message } from "antd";
import { addItem,addItems } from '../states/reducers/AddToCartSlice';
import { addWishlists } from '../states/reducers/wishlistSlice';
import { useDispatch } from 'react-redux';
import { IoKeyOutline } from "react-icons/io5";


const ProfileAccount = () => {
    const [userData, setUserData] = useState([""]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 2400);
    const fetchCartCount = async () => {
      try {
        let sessionId = sessionStorage.getItem("sessionId");
        const response = await Instance.get(
          `/api/v1/cart/?sessionId=${sessionId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );
        if (response.status === 200) {
          dispatch(addItems(response.data.cartItems))
        }
       
      } catch (error) {
        console.error("Error fetching cart count:", error);
         }
    };
  
    useEffect(() => {
      fetchCartCount(dispatch);
    },[dispatch]);
    const fetchWishlistData = async () => {
      try {
        let sessionId = sessionStorage.getItem('sessionId');
        const url = `/api/v1/wishlist/?sessionId=${sessionId}`;
        const response = await Instance.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        if (response.status === 200) {
          dispatch(addWishlists(response.data));
          console.log("wishlistData",response.data)
        }
      } catch (error) {
        console.error(error?.response?.data?.message || "An error occurred");
      }
    };
    useEffect(() => {
      fetchWishlistData(dispatch); 
    }, [dispatch]);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsLargeScreen(window.innerWidth >= 2400);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

    const fetchUserData = async () => {
        try {
          const response = await Instance.get("/api/v1/user/", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`
            }
          });
          if (response.status === 200) {
            setUserData(response.data);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          message.error(
            error?.response?.data?.message || "An error occurred"
          );
        } finally {
          setLoading(false);
        }
      };
      useEffect(() => {
        fetchUserData();
      }, []);
    
      
    
    return (
          <div className='container profile-page'>
            <div className="row">
                <div className="profile-sidebar col-lg-4 col-md-6" >
                    <div>
                        <h2>Hello {userData.fullName} </h2>
                        <h4>Welcome to your account</h4>
                         <ul>
                         <li>
                                <NavLink to="details" className={({ isActive }) => isActive ? "active-link" : ""}>
                                    <UserOutlined /> My Details
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="change-password" className={({ isActive }) => isActive ? "active-link" : ""}>
                                    <IoKeyOutline /> Change Password
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="my-orders" className={({ isActive }) => isActive ? "active-link" : ""}>
                                    <ShoppingOutlined /> My Orders
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="wishlist" className={({ isActive }) => isActive ? "active-link" : ""}>
                                    <HeartOutlined /> Wishlist
                                </NavLink>
                            </li>
                            
                            <li>
                                <a href="/profile/sign-out"><LogoutOutlined /> Sign Out</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-12 col-xl-8">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default ProfileAccount;
