import React from "react";

const INRrupee = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_42_1394)">
        <path
          d="M6.28516 3.21033H18.2852M6.28516 8.21033H18.2852M14.7852 21.2103L6.28516 13.2103H9.28516C15.9522 13.2103 15.9522 3.21033 9.28516 3.21033"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_1394">
          <rect
            width="21.1712"
            height="21.1712"
            fill="white"
            transform="translate(0.285156 0.210327)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default INRrupee;
