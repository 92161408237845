import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../states/reducers/AuthSlice";
import wishlistReducer from './reducers/wishlistSlice';
import AddToCartSlice from './reducers/AddToCartSlice';
import AddressSlice from './reducers/AddressSlice';
import CouponReducer from './reducers/CouponSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    wishlist: wishlistReducer,
    cart:AddToCartSlice,
    address: AddressSlice,
    coupon1:CouponReducer
  },
});

export default store;
