import React from 'react'
import FaqQuestions from './faqQuestions'
import FaqGetInTouch from './FaqGetInTouch'

const FAQ = () => {
  return (
    <div>
      <FaqQuestions/>
        <FaqGetInTouch/>
    </div>
  )
}

export default FAQ
