import React from "react";
import { useNavigate } from "react-router-dom";

const ContactBanner = () => {
  const navigate = useNavigate();

  const handleAbout = () => {
    navigate("/about");
  };
  return (
    <div className="container-fluid" style={{ overflow: "hidden" }}>
      <div className="row contact-banner-row">
        <div className="col-12 col-md-6 banner-content-contact  ms-md-5">
          <h2
            style={{
              fontWeight: "700",
              fontStyle:"italic",
              fontSize: "48px",
              color: "#fff",
            }}
          >
            CONTACT US
          </h2>

          <p >
            Have a question or need assistance? Reach out to Builderneed! We're
            here to help with all your inquiries about our products and
            services.
          </p>
          <button className="explore-button-shop" onClick={handleAbout}>Explore More</button>
        </div>
      </div>
    </div>
  );
};
export default ContactBanner;
